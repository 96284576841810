import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './OpenListPost.scss';
import Like from '../../icons/like.png';
import LikeActive from '../../icons/likeActive.png';
import Views from '../../icons/views.png';
import Share from '../../icons/share.png';
import Save from '../../icons/bookmark.png';
import SaveActive from '../../icons/bookmarkedD.png';
import moment from 'moment';
import { useUser } from '../../App';
import { formatFollowerCount } from '../../formatFollowerCount';
import ShareModal from '../../ShareModal';
import { DomainUrl } from '../../config';

const draftDefaultImage = '/DefaultImg/profileDefault.png';
const postDefaultImage = '/DefaultImg/postDefault.jpg';

const OpenListPost = ({
  postId,
  userId,
  userName = "Anonymous",
  userProfPic = "defaultProfilePic.jpg",
  postImages = [],
  postTitle = "Untitled",
  postContent = "No content available.",
  likes = 0,
  views = 0,
  time = "Just now",
  isLiked = false,
  isSaved = false,
}) => {
  const currentUserId = useUser();
  const navigate = useNavigate();

  const [liked, setLiked] = useState(isLiked);
  const [saved, setSaved] = useState(isSaved);
  const [likeCount, setLikeCount] = useState(likes);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  const likeIcon = liked ? LikeActive : Like;
  const saveIcon = saved ? SaveActive : Save;

  const MAX_TITLE_LENGTH = 50;
  const MAX_CONTENT_LENGTH = 200;

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isPostMenuVisible, setIsPostMenuVisible] = useState(false);

  const truncatedTitle = postTitle.length > MAX_TITLE_LENGTH
    ? `${postTitle.substring(0, MAX_TITLE_LENGTH)}...`
    : postTitle;

  const truncatedContent = postContent.length > MAX_CONTENT_LENGTH
    ? `${postContent.substring(0, MAX_CONTENT_LENGTH)}...`
    : postContent;

  const handleLikeClick = async (e) => {
    e.stopPropagation();
    if (!currentUserId) {
      setShowLoginPrompt(true);
      return;
    }

    setLiked(!liked);
    setLikeCount(liked ? likeCount - 1 : likeCount + 1);

    try {
      const response = await fetch(`${DomainUrl}/api/likes/likepost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: postId,
          userId: currentUserId,
        }),
      });

      if (!response.ok) {
        setLiked(liked);
        setLikeCount(liked ? likeCount + 1 : likeCount - 1);
        console.error('Failed to like/unlike the post');
      }
    } catch (error) {
      setLiked(liked);
      setLikeCount(liked ? likeCount + 1 : likeCount - 1);
      console.error('Error liking/unliking the post:', error);
    }
  };

  const handleSaveClick = async (e) => {
    e.stopPropagation();
    if (!currentUserId) {
      setShowLoginPrompt(true);
      return;
    }

    setSaved(!saved);

    try {
      const response = await fetch(`${DomainUrl}/api/saved/savepost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: postId,
          userId: currentUserId,
        }),
      });

      if (!response.ok) {
        setSaved(saved);
        console.error('Failed to save/unsave the post');
      }
    } catch (error) {
      setSaved(saved);
      console.error('Error saving/unsaving the post:', error);
    }
  };

  const handleShareProfile = () => {
    setIsShareModalOpen(true);
    setIsPostMenuVisible(false);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const LoginPrompt = () => (
    <div className="login-prompt-overlay">
      <div className="login-prompt-modal">
        <h3>Want to interact with posts?</h3>
        <p>Log in to like and save posts!</p>
        <button onClick={handleLoginClick} className="login-prompt-button">
          Go to Login
        </button>
        <button onClick={() => setShowLoginPrompt(false)} className="close-prompt-button">
          ✕
        </button>
      </div>
    </div>
  );

  const profileUrl = `${window.location.origin}/post/${postId}`;

  return (
    <div className="postCP">
      <div className='postElementCP'>
        <Link to={`/op/${postId}`} state={{ postId }} style={{ color: 'inherit', textDecoration: 'none' }}>
          <div className='wrapCP'>
            <img 
              src={postImages.length > 0 ? `https://heybruh.nyc3.digitaloceanspaces.com/uploads/posts/${postImages[0]}` : postDefaultImage} 
              alt={postTitle} 
              className='postImgCP' 
            />
            <div className='profDetailsCP'>
              <img 
                src={userProfPic ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${userProfPic}` : draftDefaultImage} 
                alt={userName} 
                className='profPicCP' 
              />
              <p className='userNameCP'>{userName}</p>
              <p className='timeCP'>{moment(time).fromNow()}</p>
            </div>
          </div>

          <div className='textContentCP'>
            <h1 className='postTitleCP'>
              {(() => {
                try {
                  return decodeURIComponent(truncatedTitle);
                } catch (error) {
                  console.error('Error decoding URI:', error);
                  return truncatedTitle;
                } finally {
                  if (postTitle.length > MAX_TITLE_LENGTH) {
                    return decodeURIComponent(postTitle);
                  }
                }
              })()}
            </h1>   
            
            <p className='postContentCP' dangerouslySetInnerHTML={{ __html: decodeURIComponent(truncatedContent) }} />
          </div>
        </Link>

        <div className='postFooterCP'>
          <div className='likeDivCP' onClick={handleLikeClick}>
            <img src={likeIcon} alt="like" className='likeIconCP' />
            <p className='likesCountCP'>{formatFollowerCount(likeCount)}</p>
          </div>

          <div className='viewsDivCP'>
            <img src={Views} alt="views" className='viewsIconCP' />
            <p className='viewsCountCP'>{formatFollowerCount(views)}</p>
          </div>

          <img src={Share} alt='Share Icon' onClick={handleShareProfile} className='shareCP' />

          <div className='saveDivCP' onClick={handleSaveClick}>
            <img src={saveIcon} alt='Save Icon' className='saveCP' />
          </div>
        </div>
      </div>

      {showLoginPrompt && <LoginPrompt />}
      
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        url={profileUrl}
        name={"Post"}
      />
    </div>
  );
};

export default OpenListPost;