import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './DraftList.scss';
import { useUser } from '../../App';
import DraftListShow from './DraftListShow';
import { DomainUrl } from '../../config';

const DraftList = () => {
  const [draftPosts, setDraftPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentUserId = useUser();
  const postsPerPage = 9; // Adjust as needed

  const fetchDraftPosts = useCallback(async (pageNumber) => {
    if (!currentUserId) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${DomainUrl}/api/drafts/getdraftposts`, {
        params: {
          userId: currentUserId,
          page: pageNumber,
          limit: postsPerPage
        }
      });

      const { drafts, totalPages: totalPagesFromServer } = response.data;
      
      setDraftPosts(drafts);
      setTotalPages(totalPagesFromServer);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch draft posts');
      setLoading(false);
    }
  }, [currentUserId, postsPerPage]);

  useEffect(() => {
    fetchDraftPosts(currentPage);
  }, [fetchDraftPosts, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    let startPage, endPage;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        {startPage > 1 && <span className="pagination-ellipsis">...</span>}
        {pageNumbers}
        {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    );
  };

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className='CL'>
      <h1>Draft Posts</h1>
      {draftPosts && draftPosts.length > 0 ? (
        <>
          <DraftListShow posts={draftPosts} />
          {loading && <div className="loading-message">Loading drafts...</div>}
          {!loading && draftPosts.length > 0 && renderPagination()}
        </>
      ) : (
        <p>No draft posts available.</p>
      )}
    </div>
  );
};

export default DraftList;