import { useEffect } from 'react';

const usePreventZoom = () => {
  useEffect(() => {
    const handleTouchMove = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const handleTouchStart = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && (
        e.key === '=' || 
        e.key === '-' || 
        e.key === '0' ||
        e.key === 'Add' ||
        e.keyCode === 187 || 
        e.keyCode === 189 || 
        e.keyCode === 48  || 
        e.keyCode === 107
      )) {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default usePreventZoom;