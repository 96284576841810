import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ResetPassword.scss';
import { DomainUrl } from '../../config';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || '';
  
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [timer, setTimer] = useState(120);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsOtpSent(false);
    }
    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const sendOtp = useCallback(async () => {
    try {
      setError('');
      setLoading(true);
      const response = await axios.post(`${DomainUrl}/api/auth/resetpasswordotp`, { email });
      if (response.data.success) {
        setIsOtpSent(true);
        setTimer(120);
        alert(`OTP sent to ${email}`);
      } else {
        throw new Error(response.data.message || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('Failed to send OTP:', error);
      setError(error.response?.data?.message || 'Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [email]);

  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      const response = await axios.post(`${DomainUrl}/api/auth/verify-otp`, { email, otp });
      if (response.data.success) {
        setIsOtpVerified(true);
        alert('OTP verified successfully');
      } else {
        throw new Error(response.data.message || 'Failed to verify OTP');
      }
    } catch (error) {
      console.error('Failed to verify OTP:', error);
      setError(error.response?.data?.message || 'Failed to verify OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      setError('');
      setLoading(true);
      const response = await axios.post(`${DomainUrl}/api/auth/reset-password`, { email, newPassword });
      if (response.data.success) {
        alert('Password reset successfully');
        navigate('/login');
      } else {
        throw new Error(response.data.message || 'Failed to reset password');
      }
    } catch (error) {
      console.error('Failed to reset password:', error);
      setError(error.response?.data?.message || 'Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      {error && <p className="error">{error}</p>}
      {!isOtpVerified ? (
        <>
          <p>Enter the OTP sent to {email}</p>
          <form onSubmit={verifyOtp}>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              required
            />
            <button type="submit" disabled={loading}>
              {loading ? 'Verifying...' : 'Verify OTP'}
            </button>
          </form>
          {!isOtpSent ? (
            <button onClick={sendOtp} className="send-otp-btn" disabled={loading}>
              {loading ? 'Sending...' : 'Send OTP'}
            </button>
          ) : (
            <p className="timer">Resend OTP in {timer} seconds</p>
          )}
        </>
      ) : (
        <form onSubmit={resetPassword}>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Resetting...' : 'Reset Password'}
          </button>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;