import React, { useState } from 'react';
import './ShareModal.scss';

const whatsappIcon = '/whatsappicon.png';    
const facebookIcon = '/facebook.png';    
const twitterIcon = '/twitter.png';    
const shareIcon = '/share.png';    
const copyIcon = '/copy.png';  // Assuming you have a copy icon as well

const ShareModal = ({ isOpen, onClose, url, name }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  const shareToFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };

  const shareToTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(`Check out this ${name} on Our App!`)}`, '_blank');
  };

  const shareToWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(`Check out this ${name} on Our App! ${url}`)}`, '_blank');
  };

  const shareToOther = () => {
    if (navigator.share) {
      navigator.share({
        title: `Check out this ${name} on Our App!`,
        url: url
      }).catch(console.error);
    } else {
      alert("Your browser doesn't support direct sharing. You can copy the link and share it manually.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="share-modal">
      <div className="share-modal__content">
        <h2 className="share-modal__title">Share {name}</h2>
        <div className="share-modal__url-container">
          <input
            type="text"
            value={url}
            readOnly
            className="share-modal__url-input"
          />
          <button
            onClick={copyToClipboard}
            className="share-modal__copy-button"
          >
            <img src={copyIcon} alt="Copy" />
            {isCopied ? 'Copied!' : 'Copy'}
          </button>
        </div>
        <div className="share-modal__social-buttons">
          <SocialButton icon={facebookIcon} name="Facebook" onClick={shareToFacebook} />
          <SocialButton icon={twitterIcon} name="Twitter" onClick={shareToTwitter} />
          <SocialButton icon={whatsappIcon} name="WhatsApp" onClick={shareToWhatsApp} />
          <SocialButton icon={shareIcon} name="Other" onClick={shareToOther} />
        </div>
        <button
          onClick={onClose}
          className="share-modal__close-button"
        >
          x
        </button>
      </div>
    </div>
  );
};

const SocialButton = ({ icon, name, onClick }) => (
  <button className="share-modal__social-button" onClick={onClick}>
    <img src={icon} alt={name} className="icon" />
    <span className="name">{name}</span>
  </button>
);

export default ShareModal;