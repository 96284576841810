import React, { useState, useEffect, useRef, useCallback  } from 'react';
import './CreatePost.scss';
import 'react-quill/dist/quill.snow.css'; 
import ReactQuill from 'react-quill';
import { useUser } from '../../App';
import { DomainUrl } from '../../config';


const CreatePost = () => {
  const [images, setImages] = useState([]);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const [categoryError, setCategoryError] = useState('');

  const quillRef = useRef(null);
  const dropdownRef = useRef(null);
  const user_id = useUser();


  // UseEffects

  // Load dark mode preference from localStorage on component mount
  useEffect(() => {
    const savedTitle = localStorage.getItem('postTitle');
    const savedCategory = localStorage.getItem('postCategory');
    const savedContent = localStorage.getItem('postContent');

    if (savedTitle) setTitle(savedTitle);
    if (savedCategory) setCategory(savedCategory);
    if (savedContent) {
      setContent(savedContent);
      setCharCount(savedContent.length);
    }

    const savedMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedMode);
  }, []);

  useEffect(() => {
    localStorage.setItem('postTitle', title);
    localStorage.setItem('postCategory', category);
    localStorage.setItem('postContent', content);
  }, [title, category, content]);


  // Prevent pasting and dropping images
  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops = delta.ops.filter(op => !op.insert || typeof op.insert === 'string');
        return delta;
      });

      quill.root.addEventListener('drop', (event) => {
        event.preventDefault();
      });
    }
  }, []);

  // Filter categories based on input
  useEffect(() => {
    if (category) {
      const filtered = categories.filter((cat) =>
        cat.toLowerCase().includes(category.toLowerCase())
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(categories);
    }
  }, [category, categories]);

  // Handle clicks outside the category section to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  // Methods

  const containsEmoji = (text) => {
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    return emojiRegex.test(text);
  };

  const handleCategoryChange = async (e) => {
    const value = e.target.value;
    
    if (containsEmoji(value)) {
      setCategoryError('Category cannot contain emoji');
    } else {
      setCategoryError('');
    }

    setCategory(value);
    setShowDropdown(true);

    if (value.length > 0 && !containsEmoji(value)) {
      setIsLoading(true);
      try {
        const response = await fetch(`${DomainUrl}/api/posts/categorysearch?query=${encodeURIComponent(value)}`);
        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        } else {
          console.error('Failed to fetch categories');
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setCategories([]);
    }
  };

  const handleImageUpload = (e) => {
    if (images.length >= 5) return; // Limit to 5 images
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files].slice(0, 5));
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode); // Save preference
      return newMode;
    });
  };

  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);
    setShowDropdown(false);
  };

  const handleToggleDropdown = () => {
    setShowDropdown((prevShow) => !prevShow);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
      [{ 'align': [] }],
      ['clean']
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link',
    'align'
  ];

  const handleContentChange = (value) => {
    const text = quillRef.current.getEditor().getText();
    const newCharCount = text.length - 1;

    if (newCharCount <= 15000) {
      setContent(value);
      setCharCount(newCharCount);
      localStorage.setItem('postContent', value);
    } else {
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
    }
  };

  const handleSubmit = async (e, isDraft) => {
    e.preventDefault();

    if (containsEmoji(category)) {
      setCategoryError('Category cannot contain emoji');
      return; // Prevent form submission
    }
    
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (!title && !category && !content && images.length === 0) {
      isDraft ? alert('Please fill at least one field.') 
      : alert('Please fill heading, category and post content.');
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('title', encodeURIComponent(title));
    formData.append('category', encodeURIComponent(category));
    formData.append('content', encodeURIComponent(content));
    formData.append('isDraft', isDraft);

    images.forEach((image) => {
      formData.append('images', image);
    });

    try {
      const response = await fetch(
        `${DomainUrl}/api/${
          isDraft ? 'drafts/uploaddraft' : 'posts/uploadpost'
        }`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (response.ok) {
        alert(isDraft ? 'Draft saved successfully!' : 'Post uploaded successfully!');
        // Clear form and localStorage
        setTitle('');
        setCategory('');
        setContent('');
        setImages([]);
        setCharCount(0);
        localStorage.removeItem('postTitle');
        localStorage.removeItem('postCategory');
        localStorage.removeItem('postContent');
      } else {
        alert('Failed to submit post.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting post.');
    } finally {
      setIsSubmitting(false);
    }
  };



  return (
    <div className={`create-post ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <button className="toggle-mode" onClick={toggleDarkMode}>
        {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
      </button>
  
      <h1 className="post-heading">Create Post</h1>
  
      <form>
        <input
          type="text"
          placeholder="Enter post title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className={`post-title ${isDarkMode ? 'dark-mode' : ''}`}
          required
        />
  
  <div className="category-section" ref={dropdownRef}>
        <div className="category-input-container">
          <input
            type="text"
            placeholder="Enter or Select Category"
            value={decodeURIComponent(category)}
            onChange={handleCategoryChange}
            onFocus={() => setShowDropdown(true)}
            className={`category-input ${isDarkMode ? 'dark-mode' : ''} ${categoryError ? 'error' : ''}`}
            required
          />
          
        </div>
        {categoryError && (
          <div className="error-message">{categoryError}</div>
        )}
        {showDropdown && (
          <ul className="category-dropdown">
            {isLoading ? (
              <li>Loading...</li>
            ) : categories.length > 0 ? (
              categories.map((cat, index) => (
                <li key={index} onClick={() => handleCategorySelect(cat)}>
                  {decodeURIComponent(cat)}
                </li>
              ))
            ) : (
              <li>No matching categories</li>
            )}
          </ul>
        )}
      </div>
  
        <div className="image-upload">
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            disabled={images.length >= 5}
          />
          <div className="image-preview">
            {images.map((image, index) => (
              <div key={index} className="image-container">
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Upload Preview ${index + 1}`}
                />
                <button type="button" onClick={() => handleRemoveImage(index)}>x</button>
              </div>
            ))}
          </div>
        </div>
  
        <div className="quill-section">
          <ReactQuill
            ref={quillRef}
            value={content}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
            className={`post-content ${isDarkMode ? 'dark-mode' : ''}`}
            placeholder="Write your post content here..."
            required
          />
          <div className="char-count">{charCount}/15000 characters</div>
        </div>
  
        {showPopup && (
          <div className="popup">Character limit reached (15000 characters)</div>
        )}
  
        <div className="action-buttons">
          <button
            type="button"
            className="save-draft"
            onClick={(e) => handleSubmit(e, true)}
          >
            Save as Draft
          </button>
          <button
            type="submit"
            className="publish"
            onClick={(e) => handleSubmit(e, false)}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Publishing...' : 'Publish'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreatePost;
