import React from 'react';
import './OpenListShow.scss';
import OpenListPost from './OpenListPost';

const OpenListShow = ({ posts = [] }) => {
    // Ensuring posts is always an array
    

    return (
        <div className='CLS'>
            {posts.length > 0 ? (
                posts.map((post) => (
                    <OpenListPost
                        key={post.id}
                        postId={post.post_id}
                        userId={post.user_id}
                        userName = {post.username}
                        userProfPic = {post.profile_picture}
                        postImages={post.images || []} // Ensure it's an array
                        postTitle={decodeURIComponent(post.title) || "Untitled"} // Default title
                        postContent={decodeURIComponent(post.content) || "No content available."} // Default content
                        likes={post.likes_count || 0} // Default to 0 likes
                        views={post.views || 0} // Default to 0 views
                        time={post.created_at || "Just now"} // Default time value
                        isLiked = {post.is_liked || false}
                        isSaved = {post.is_saved || false}
                    />
                ))
            ) : (
                <p>No posts available.</p> // Message if no posts are found
            )}
        </div>
    );
};

export default OpenListShow;
