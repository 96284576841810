import React, { useState } from 'react';
import './ReportModal.scss';
import { DomainUrl } from './config';

const ReportModal = ({ isOpen, onClose, context, reportedId, commentId, currentUserId }) => {
  const [selectedReason, setSelectedReason] = useState([]);
  const [customReason, setCustomReason] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const predefinedReasons = {
    user: [
      "Harassment or bullying",
      "Impersonation",
      "Inappropriate behavior",
      "Spam",
      "Hate speech",
      "Violence",
      "Fraud or scam",
      "Other"
    ],
    post: [
      "Spam",
      "Misinformation",
      "Inappropriate content",
      "Copyright infringement",
      "Hate speech",
      "Violence",
      "Terrorism",
      "Other"
    ],
    comment: [
      "Harassment or bullying",
      "Spam",
      "Hate speech",
      "Inappropriate content",
      "Misinformation",
      "Violence",
      "Other"
    ]
  };

  const handleCheckboxChange = (reason) => {
    if (selectedReason.includes(reason)) {
      setSelectedReason(selectedReason.filter(r => r !== reason));
    } else {
      setSelectedReason([...selectedReason, reason]);
    }
  };

  const handleSubmit = async () => {
    const reportContent = {
      reportingUserId: currentUserId,
      reportedId: reportedId,  // postId or userId depending on context
      reportContext: context,
      reasons: selectedReason,
      additionalInfo: customReason
    };

    // Add commentId to the report content if the context is 'comment'
    if (context === 'comment' && commentId) {
      reportContent.commentId = commentId;
    }

    try {
      // Send the report to the backend
      const response = await fetch(`${DomainUrl}/api/users/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reportContent)
      });

      if (response.ok) {
        // Report submitted successfully
        setIsSubmitted(true);
        // Reset after 2 seconds and close modal
        setTimeout(() => {
          onClose();
          resetForm();
        }, 2000);
      } else {
        console.error('Failed to submit the report');
      }
    } catch (error) {
      console.error('Error submitting the report:', error);
    }
  };

  const resetForm = () => {
    setIsSubmitted(false);
    setSelectedReason([]);
    setCustomReason('');
  };

  if (!isOpen) return null;

  const reasonsList = predefinedReasons[context] || [];

  return (
    <div className="report-modal">
      <div className="report-modal__content">
        <h2 className="report-modal__title">
          Report {context === 'user' ? 'User' : context === 'post' ? 'Post' : 'Comment'}
        </h2>
        
        <div className="report-modal__checkboxes">
          {reasonsList.map((reason, index) => (
            <div key={index} className="report-modal__checkbox">
              <input
                type="checkbox"
                id={`reason-${index}`}
                value={reason}
                checked={selectedReason.includes(reason)}
                onChange={() => handleCheckboxChange(reason)}
              />
              <label htmlFor={`reason-${index}`}>{reason}</label>
            </div>
          ))}
        </div>

        {selectedReason.includes('Other') && (
          <textarea
            className="report-modal__textarea"
            placeholder="Please describe your issue..."
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
          />
        )}

        <div className="report-modal__buttons">
          <button
            onClick={handleSubmit}
            className="report-modal__submit-button"
            disabled={isSubmitted || (!selectedReason.length && !customReason)}
          >
            {isSubmitted ? 'Submitted' : 'Submit Report'}
          </button>
          <button
            onClick={onClose}
            className="report-modal__close-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;