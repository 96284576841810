import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Comments.scss';
import moment from 'moment';
import { useUser } from '../../App';
import { formatFollowerCount } from '../../formatFollowerCount';
import MenuIcon from '../../icons/threeDots.png';
import ReportModal from '../../ReportModal';
import { DomainUrl } from '../../config';

const profileDefaultImage = '/DefaultImg/profileDefault.png';

const Comments = ({ postId }) => {
    const currentUserId = useUser();

    const [comments, setComments] = useState([]);
    const [activeComment, setActiveComment] = useState(null);
    const [newComment, setNewComment] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isPostOwner, setIsPostOwner] = useState(false);
    const [editingComment, setEditingComment] = useState(null);
    const [editText, setEditText] = useState('');

    const COMMENTS_PER_PAGE = 10;

    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  
    const openReportModal = () => setIsReportModalOpen(true);
    const closeReportModal = () => setIsReportModalOpen(false);

    useEffect(() => {
        if (!currentUserId) {
            console.warn("currentUserId is undefined, aborting fetch");
            return;
        }
        setComments([]); // Clear comments when postId changes
        setPage(1); // Reset page to 1
        setHasMore(true); // Reset hasMore
        fetchComments(1); // Fetch first page of comments
    }, [postId, currentUserId]);

    const fetchComments = async (pageToFetch) => {
        if (isLoading || !hasMore) return;
    
        setIsLoading(true);
        try {
            const response = await fetch(`${DomainUrl}/api/comments/retrievecomment?postId=${postId}&userId=${currentUserId}&page=${pageToFetch}&limit=${COMMENTS_PER_PAGE}`);
            if (response.ok) {
                const data = await response.json();
                if (Array.isArray(data.comments)) {
                    setComments(prevComments => {
                        const newComments = pageToFetch === 1 ? data.comments : [...prevComments, ...data.comments];
                        return newComments.filter((comment, index, self) =>
                            index === self.findIndex((t) => t.id === comment.id)
                        );
                    });
                    setPage(pageToFetch + 1);
                    setHasMore(data.hasMore);
                    setIsPostOwner(data.isPostOwner); // Store isPostOwner in state
                    console.log("hi: ",data, "postId: ", postId)
                } else {
                    console.error('Received comments is not an array:', data);
                }
            } else {
                console.error('Failed to fetch comments');
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleMenu = (commentId) => {
        setActiveComment(activeComment === commentId ? null : commentId);
    };

    const handleCommentSubmit = async () => {
        if (newComment.trim() && !isSubmitting) {
            setIsSubmitting(true);
            try {
                const response = await fetch(`${DomainUrl}/api/comments/writecomment`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        postId: postId,
                        userId: currentUserId,
                        commentText: encodeURIComponent(newComment.trim())
                    }),
                });
    
                if (response.ok) {
                    const result = await response.json();
                    const addedComment = {
                        id: result.comment.id,
                        postId: result.comment.postId,
                        userId: result.comment.userId,
                        userName: result.comment.userName,
                        userProfilePicture: result.comment.userProfilePicture,
                        commentText: decodeURIComponent(result.comment.commentText),
                        createdAt: result.comment.createdAt
                    };
                    setComments(prevComments => [addedComment, ...prevComments]);
                    setNewComment('');
                } else {
                    const errorData = await response.json();
                    console.error('Failed to add comment:', errorData.message);
                    alert('Failed to add comment. Please try again.');
                }
            } catch (error) {
                console.error('Error adding comment:', error);
                alert('An error occurred while adding the comment. Please try again.');
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleDeleteComment = async (commentId) => {
        if (window.confirm("Are you sure you want to delete this comment?")) {
            try {
                const response = await fetch(`${DomainUrl}/api/comments/deletecomment`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        commentId: commentId,
                        userId: currentUserId,
                        postId: postId
                    }),
                });

                if (response.ok) {
                    // Remove the deleted comment from the state
                    setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
                } else {
                    const errorData = await response.json();
                    console.error('Failed to delete comment:', errorData.message);
                    alert('Failed to delete comment. Please try again.');
                }
            } catch (error) {
                console.error('Error deleting comment:', error);
                alert('An error occurred while deleting the comment. Please try again.');
            }
        }
    };

    const handleEditComment = (comment) => {
        setEditingComment(comment.id);
        setEditText(comment.commentText);
        setActiveComment(null);
    };

    const handleCancelEdit = () => {
        setEditingComment(null);
        setEditText('');
    };

    const handleSaveEdit = async (commentId) => {
        if (editText.trim() && !isSubmitting) {
            setIsSubmitting(true);
            try {
                const response = await fetch(`${DomainUrl}/api/comments/editcomment`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        commentId: commentId,
                        userId: currentUserId,
                        commentText: encodeURIComponent(editText.trim())
                    }),
                });
    
                if (response.ok) {
                    const updatedComment = await response.json();
                    setComments(prevComments =>
                        prevComments.map(comment =>
                            comment.id === commentId ? { ...comment, commentText: decodeURIComponent(updatedComment.commentText) } : comment
                        )
                    );
                    setEditingComment(null);
                    setEditText('');
                } else {
                    const errorData = await response.json();
                    console.error('Failed to edit comment:', errorData.message);
                    alert('Failed to edit comment. Please try again.');
                }
            } catch (error) {
                console.error('Error editing comment:', error);
                alert('An error occurred while editing the comment. Please try again.');
            } finally {
                setIsSubmitting(false);
            }
        }
    };



    return (
        <div className="comments-container">
            <h3>Comments ({formatFollowerCount(comments.length)})</h3>
            <div className="comment-input-container">
                <textarea
                    className="comment-input"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Write a comment..."
                    disabled={isSubmitting}
                />
                <button 
                    className="comment-submit" 
                    onClick={handleCommentSubmit}
                    disabled={isSubmitting || !newComment.trim()}
                >
                    {isSubmitting ? '...' : '➡'}
                </button>
            </div>
            <div className="comments-list">
                {comments.map((comment) => {
                    const isCommentOwner = currentUserId === comment.userId;

                    return (
                        <div key={comment.id} className="comment">
                            <Link to={`/user/${comment.userName}`} className="user-link">
                                <img
                                    src={comment.userProfilePicture ?  `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${comment.userProfilePicture}`: profileDefaultImage }
                                    alt={comment.userName || 'Unknown user'}
                                    className="user-avatar"
                                />
                                <span className="user-name">{comment.userName || 'Unknown user'}</span>
                            </Link>

                            {editingComment === comment.id ? (
                                    <div className="edit-comment-form">
                                    <textarea
                                        value={editText}
                                        onChange={(e) => setEditText(e.target.value)}
                                        placeholder="Edit your comment..."
                                    />
                                    <div className="edit-cmtbuttons">
                                        <button 
                                        className="save-editbtn" 
                                        onClick={() => handleSaveEdit(comment.id)} 
                                        disabled={isSubmitting}
                                        >
                                        {isSubmitting ? 'Saving...' : 'Save'}
                                        </button>
                                        <button className="cancel-editbtn" onClick={handleCancelEdit}>Cancel</button>
                                    </div>
                                    </div>
                                ) : (
                                <>
                                    <p className="comment-content">{comment.commentText}</p>
                                    <span className="comment-time">{moment(comment.createdAt).fromNow()}</span>

                                    <div className="comment-menu">
                                        <img
                                            src={MenuIcon}
                                            alt="menu"
                                            className="menu-icon"
                                            onClick={() => toggleMenu(comment.id)}
                                        />
                                        {activeComment === comment.id && (
                                            <div className="dropdown-menu">
                                                {isCommentOwner ? (
                                                    <>
                                                        <button onClick={() => handleEditComment(comment)}>Edit</button>
                                                        <button onClick={() => handleDeleteComment(comment.id)}>Delete</button>
                                                    </>
                                                ) : isPostOwner ? (
                                                    <button onClick={() => handleDeleteComment(comment.id)}>Delete</button>
                                                ) : (
                                                    <button onClick={openReportModal}>Report</button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            <ReportModal
                            isOpen={isReportModalOpen}
                            onClose={closeReportModal}
                            context="comment"
                            reportedId={postId}
                            commentId = {comment.id}
                            currentUserId={currentUserId}
                            />
                        </div>
                        
                    );
                })}
                {comments.length === 0 && <p>No comments yet. Be the first to comment!</p>}
                {hasMore && (
                    <button 
                        className="load-more-button" 
                        onClick={() => fetchComments(page)}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : 'Load More Comments'}
                    </button>
                )}

                
            </div>
        </div>
    );
};

export default Comments;

// Break Point 3