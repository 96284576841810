import './App.scss';
import usePreventZoom from './usePreventZoom';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; 
import { useEffect, useState, createContext, useContext } from 'react';

import Login from './components/login/Login';
import Register from './components/register/Register';
import ResetPassword from './components/resetPassword/ResetPassword';
import AboutPage from './components/about/About';
import OpenList from './components/openList/OpenList';
import OpenPost from './components/openPost/OpenPost';
import UserInfo from './components/userInfo/UserInfo';
import Navbar from './components/navBar/NavBar';
import Header from './components/header/Header';
import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import Search from './pages/search/Search';
import Explore from './pages/explore/Explore';
import CategoryList from './pages/categoryList/CategoryList';
import CreatePost from './pages/createPost/CreatePost';
import DraftList from './pages/draftList/DraftList';
import PostList from './pages/postList/PostList';
import DraftPost from './pages/draftPost/DraftPost';
import EditPost from './pages/editPost/EditPost';
import EditProfile from './pages/editProfile/EditProfile';
import FriendsList from './pages/friendsList/FriendsList';
import SavedList from './pages/savedList/SavedList';
import SelfSinglePost from './pages/selfSinglePost/SelfSinglePost';
import UserProfile from './pages/userProfile/UserProfile';
import AccountSetting from './pages/accSettings/AccSettings';
import SinglePost from './pages/singlePost/SinglePost';

// Create UserContext to store userId globally
export const UserContext = createContext();
export const useUser = () => useContext(UserContext);

function App() {
  usePreventZoom();

  const Layout = () => (
    <div className='brView'>
      <div className='header'>
        <Header />
      </div>
      <div className='navBar'>
        <Navbar />
      </div>
      <div className='outet'>
        <Outlet />
      </div>
    </div>
  );

  // ProtectedRoute component that checks for token in localStorage
  const ProtectedRoute = ({ children }) => {
    const [userId, setUserId] = useState(null);

    useEffect(() => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          setUserId(decodedToken.userId); // Extract userId from token
        } catch (error) {
          console.error('Invalid token', error);
          localStorage.removeItem('token'); // Remove invalid token
        }
      }
    }, []);

    // If no token, redirect to login
    if (!localStorage.getItem('token')) {
      return <Navigate to="/login" />;
    }

    // Pass userId globally to the application
    return (
      <UserContext.Provider value={userId}>
        {children}
      </UserContext.Provider>
    );
  };

  // Redirection for Login, Register, and UserInfo if user is already logged in
  const PublicRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    const userId = token ? jwtDecode(token).userId : null;

    // If token exists, redirect to home
    if (userId) {
      return <Navigate to="/" />;
    }

    return children;
  };

  // Define routes
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        { path: '/', element: <Home /> },
        { path: '/profile', element: <Profile /> },
        { path: '/search', element: <Search /> },
        { path: '/explore', element: <Explore /> },
        { path: '/categoryList/:id', element: <CategoryList /> },
        { path: '/create-post', element: <CreatePost /> },
        { path: '/draft-list', element: <DraftList /> },
        { path: '/post-list/:id', element: <PostList /> },
        { path: '/draft-post', element: <DraftPost /> },
        { path: '/edit-post/:from/:id', element: <EditPost /> },
        { path: '/edit-profile', element: <EditProfile /> },
        { path: '/acc-setting', element: <AccountSetting /> },
        { path: '/friends', element: <FriendsList /> },
        { path: '/saved', element: <SavedList /> },
        { path: '/single-post/:id', element: <SelfSinglePost /> },
        { path: '/post/:id', element: <SinglePost /> },
        { path: '/user/:id', element: <UserProfile /> }
      ]
    },
    {
      path: '/login',
      element: (
        <PublicRoute>
          <Login />
        </PublicRoute>
      )
    },
    {
      path: '/register',
      element: (
        <PublicRoute>
          <Register />
        </PublicRoute>
      )
    },
    {
      path: '/reset-password',
      element: (
          <ResetPassword/>
      )
    },
    {
      path: '/about',
      element: (
          <AboutPage/>
      )
    },
    {
      path: '/ol/:id',
      element: (
          <OpenList/>
      )
    },
    {
      path: '/op/:id',
      element: (
          <OpenPost/>
      )
    },
    {
      path: '/profile-details',
      element: (
        <PublicRoute>
          <UserInfo />
        </PublicRoute>
      )
    }
  ]);

  return (
    <div className='outlet'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
