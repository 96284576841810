import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './OpenList.scss';
import OpenListShow from './OpenListShow';
import { useUser } from '../../App';
import { DomainUrl } from '../../config';
import { jwtDecode } from 'jwt-decode';

const OpenList = () => {
  const { id } = useParams();
  const category = id;
  const navigate = useNavigate();
  const [userId, setUserId] = useState();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const postsPerPage = 9;

  const cachedPosts = useRef({});

  // Modified useEffect to properly check currentUserId
  useEffect(() => {
    const checkUserAndNavigate = async () => {
      try {
        // Check if user is logged in by validating token
        const token = localStorage.getItem('token');
          if (token) {
            try {
              const decodedToken = jwtDecode(token);
              setUserId(decodedToken.userId); // Extract userId from token
            } catch (error) {
              console.error('Invalid token', error);
            }
          }
          
          if (localStorage.getItem('token')) {
            navigate(`/categoryList/${category}`);
          }
        
      } catch (err) {
        // If token validation fails, clear token
        localStorage.removeItem('token');
      }
    };

    checkUserAndNavigate();
  }, [category, navigate]);

  const fetchCategoryPosts = useCallback(async (pageNumber) => {
    if (!category) return;

    if (cachedPosts.current[pageNumber]) {
      setPosts(cachedPosts.current[pageNumber]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${DomainUrl}/api/posts/getolcategoryposts`, {
        params: {
          category: encodeURIComponent(category),
          page: pageNumber,
          limit: postsPerPage
        }
      });

      const newPosts = response.data.posts || [];
      cachedPosts.current[pageNumber] = newPosts;

      setPosts(newPosts);
      setTotalPages(response.data.totalPages || 1);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch category posts');
      setLoading(false);
    }
  }, [category, postsPerPage]);

  useEffect(() => {
    // Check if there's no valid token before fetching posts
    const token = localStorage.getItem('token');
    if (!token) {
      fetchCategoryPosts(currentPage);
    }
  }, [fetchCategoryPosts, currentPage, category]);

  const handlePageChange = (newPage) => {
    // Check if user is not logged in and trying to access beyond page 1
    const token = localStorage.getItem('token');
    if (!token && currentPage === 1) {
      setShowLoginPrompt(true);
      return;
    }
    setCurrentPage(newPage);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const LoginPrompt = () => (
    <div className="login-prompt-overlay">
      <div className="login-prompt-modal">
        <h3>Want to see more?</h3>
        <p>Log in to continue exploring more posts in this category!</p>
        <button onClick={handleLoginClick} className="login-prompt-button">
          Go to Login
        </button>
        <button onClick={() => setShowLoginPrompt(false)} className="close-prompt-button">
          ✕
        </button>
      </div>
    </div>
  );

  const renderPagination = () => {
    const pageNumbers = [];
    let startPage, endPage;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        {startPage > 1 && <span className="pagination-ellipsis">...</span>}
        {pageNumbers}
        {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    );
  };

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className='CLOL'>
      <div className='olHeader'>
        <Link to="/">
          <img src='/HeyBruhlogo.png' alt='HeyBruh! Logo' className='logo'></img>
        </Link>
        
        <button className="olmenu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          ☰
        </button>

        <nav className={`olnav-links ${menuOpen ? 'open' : ''}`}>
          <Link to="/about" style={{ color: 'inherit', textDecoration: 'none' }}>
            <span className='olAbout'> About </span>
          </Link>
          <Link to="/login" style={{ color: 'inherit', textDecoration: 'none' }}>
            <span className='olSignup'> Log in </span>
          </Link>
          <Link to="/register" style={{ color: 'inherit', textDecoration: 'none' }}>
            <span className='olLogin'> Sign up </span>
          </Link>
        </nav>
      </div>
      <h1 className='OLcatName'>{decodeURIComponent(category)}</h1>
      {posts && posts.length > 0 ? (
        <>
          <OpenListShow posts={posts} />
          {loading && <div className="loading-message">Loading posts...</div>}
          {!loading && posts.length > 0 && renderPagination()}
        </>
      ) : (
        <p>No posts available in this category.</p>
      )}
      {showLoginPrompt && <LoginPrompt />}
    </div>
  );
};

export default OpenList;