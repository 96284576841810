import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Search.scss';
import { DomainUrl } from '../../config';
const profileDefaultImage = '/DefaultImg/profileDefault.png';

const Search = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchHistory, setSearchHistory] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedHistory = localStorage.getItem('searchHistory');
    if (storedHistory) {
      setSearchHistory(JSON.parse(storedHistory));
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchInput.trim() !== '') {
        fetchUsers(searchInput, 1);
      } else {
        setUsers([]);
        setTotalPages(0);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);
  };

  const fetchUsers = async (query, page) => {
    setLoading(true);
    try {
      const response = await axios.get(`${DomainUrl}/api/search/getsearchresults`, {
        params: {
          query,
          page,
          limit: 10// Number of users per page
        }
      });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchUsers(searchInput, newPage);
  };

  const handleClearSearchHistory = (index) => {
    const newHistory = [...searchHistory];
    newHistory.splice(index, 1);
    setSearchHistory(newHistory);
    localStorage.setItem('searchHistory', JSON.stringify(newHistory));
  };

  const handleSearchHistoryItemClick = (item) => {
    setSearchInput(item);
    setCurrentPage(1);
    fetchUsers(item, 1);
  };

  const updateSearchHistory = () => {
    if (searchInput.trim() !== '' && !searchHistory.includes(searchInput)) {
      const newHistory = [searchInput, ...searchHistory];
      setSearchHistory(newHistory);
      localStorage.setItem('searchHistory', JSON.stringify(newHistory));
    }
  };

  return (
    <div>
      <div className='searchContainer'>
        <div className='searchWrap'>
          <div className='searchBox'>
            <input
              type='text'
              placeholder='Search...'
              value={searchInput}
              onChange={handleSearchInput}
              onBlur={updateSearchHistory}
            />
          </div>

          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className='searchUserList'>
              {users.map(user => (
                <Link 
                  to={`/user/${user.userName}`} 
                  key={user.id} 
                  className='userLink'
                >
                  <div className='userItem'>
                  <img src={user.profilePicture ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${user.profilePicture}` : profileDefaultImage} 
                  alt={user.userName} />
                    <span>{user.userName}</span>
                  </div>
                </Link>
              ))}
            </div>
          )}

{totalPages > 1 && (
  <div className="pagination">
    <button
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
    >
      &lt; {/* Previous button */}
    </button>

    {/* Calculate the range of pages to display */}
    {(() => {
      let start = Math.max(1, currentPage - 2);
      let end = Math.min(totalPages, start + 4);
      start = Math.max(1, end - 4);
      
      return Array.from({ length: end - start + 1 }, (_, i) => {
        let page = start + i;
        return (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={page === currentPage ? 'active' : ''}
            disabled={page === currentPage}
          >
            {page}
          </button>
        );
      });
    })()}

    <button
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
    >
      &gt; {/* Next button */}
    </button>
  </div>
)}
          <div className='searchHistory'>
            {searchHistory.map((item, index) => (
              <div
                key={index}
                className='searchHistoryItem'
                onClick={() => handleSearchHistoryItemClick(item)}
              >
                {item}
                <button
                  className='clearButton'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClearSearchHistory(index);
                  }}
                >
                  x
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;