import React from 'react';
import { Link } from 'react-router-dom';
import './DraftPost.scss';
import moment from 'moment';

const draftDefaultImage = '/DefaultImg/profileDefault.png';
const postDefaultImage = '/DefaultImg/draftDefault.jpg';

const DraftPost = ({ 
  postId,
  userName = "Anonymous",
  userProfPic = "defaultProfilePic.jpg",
  postImages = [],
  postTitle = "Untitled",
  postContent = "No content available.",
  time = "Just now",
}) => {
  const MAX_TITLE_LENGTH = 50;
  const MAX_CONTENT_LENGTH = 250;
  const from = 'DraftPost';

  // Safe decode function
  const safeDecodeURI = (str) => {
    try {
      return decodeURIComponent(str);
    } catch (error) {
      console.error('Error decoding URI:', error);
      return str; // Return original string if decoding fails
    }
  };

  // Truncate and decode title
  const truncatedTitle = (() => {
    const decodedTitle = safeDecodeURI(postTitle);
    return decodedTitle.length > MAX_TITLE_LENGTH
      ? `${decodedTitle.substring(0, MAX_TITLE_LENGTH)}...`
      : decodedTitle;
  })();

  // Truncate and decode content
  const truncatedContent = (() => {
    const decodedContent = safeDecodeURI(postContent);
    return decodedContent.length > MAX_CONTENT_LENGTH
      ? `${decodedContent.substring(0, MAX_CONTENT_LENGTH)}...`
      : decodedContent;
  })();

  return (
    <div className="postDP">
      <div className='postElementDP'>
        <Link to={`/edit-post/${from}/${postId}`} state={{ draft_id: postId, from }} style={{ color: 'inherit', textDecoration: 'none' }}>
          <div className='wrapDP'>
            <img 
              src={postImages.length > 0 ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${postImages[0]}` : postDefaultImage} 
              alt={truncatedTitle} 
              className='postImgDP' 
            />
            
            <div className='profDetailsDP'>
              <img 
                src={userProfPic ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${userProfPic}` : draftDefaultImage} 
                alt={userName} 
                className='profPicDP'
              />
              <p className='userNameDP'>{userName}</p>
              <p className='timeDP'>{moment(time).fromNow()}</p>
            </div>
          </div>
          
          <div className='textContentCP'>
            <h2 className='postTitleCP'>{truncatedTitle}</h2>   
            <p 
              className='postContentCP' 
              dangerouslySetInnerHTML={{ __html: truncatedContent }} 
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default DraftPost;