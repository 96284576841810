import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './UserInfo.scss';
import { DomainUrl } from '../../config';

function UserInfo() {
  const uselocation = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [usernameAvailability, setUsernameAvailability] = useState('');
  const [currentInterest, setCurrentInterest] = useState('');
  const [interests, setInterests] = useState([]);
  const [interestError, setInterestError] = useState(''); // To store emoji error message
  const [profession, setProfession] = useState('');
  const [location, setLocation] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [professionError, setProfessionError] = useState('');
  const [locationError, setLocationError] = useState('');


  // Fetch email and password from navigation state
  useEffect(() => {
    if (uselocation.state) {
      setEmail(uselocation.state.email);
      setPassword(uselocation.state.password);
    }
  }, [uselocation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (username) {
        checkUsernameAvailability(username);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [username]);

  // Handle form submission to the db
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateUsername(username) && validateProfession(profession) && validateLocation(location)) {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('profession', profession);
      formData.append('location', location);
      formData.append('interests', interests);
      if (profilePic) {
        formData.append('profile_picture', profilePic);
      }

      fetch(`${DomainUrl}/api/auth/register`, {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.error) {
            alert(`Error: ${data.error}`); // Show error in alert box
          } else {
            try {
              const token = data.token;
              localStorage.setItem('token', token);
              window.location.href = '/';
            } catch (error) {
              console.error('Error decoding token or parsing payload:', error);
            }
          }
        })
        .catch(error => {
          alert(`An error occurred: ${error.message}`);
        });
    } else {
      console.log("Username validation failed");
    }
  };

  // Emoji detection using regex
  const containsEmoji = (text) => {
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    return emojiRegex.test(text);
  };

  const removeInterest = (interestToRemove) => {
    setInterests(interests.filter((interest) => interest !== interestToRemove));
  };

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePic(file); // Store the image file for submission
    }
  };

  const validateUsername = (name) => {
    const usernameRegex = /^[a-zA-Z0-9._]{3,15}$/;
    if (!usernameRegex.test(name)) {
      setUsernameError(
        'Username must be 3-15 characters and contain only letters, numbers, underscores, and periods.'
      );
      return false;
    }
    if (name.includes(' ')) {
      setUsernameError('Username cannot contain spaces.');
      return false;
    }
    if (name.startsWith('.') || name.endsWith('.')) {
      setUsernameError('Username cannot start or end with a period.');
      return false;
    }
    setUsernameError('');
    return true;
  };

  const checkUsernameAvailability = (name) => {
    if (validateUsername(name)) {
      fetch(`${DomainUrl}/api/auth/checkusername?username=${name}`)
        .then(response => response.json())
        .then(data => {
          if (data.available) {
            setUsernameAvailability('Username is available');
          } else {
            setUsernameAvailability('Username is already taken');
          }
        })
        .catch(error => {
          console.error('Error checking username availability:', error);
          setUsernameAvailability('');
        });
    } else {
      setUsernameAvailability('');
    }
  };

  const validateProfession = (value) => {
    if (containsEmoji(value)) {
      setProfessionError('Profession cannot contain emojis.');
      return false;
    }
    setProfessionError('');
    return true;
  };

  const validateLocation = (value) => {
    if (containsEmoji(value)) {
      setLocationError('Location cannot contain emojis.');
      return false;
    }
    setLocationError('');
    return true;
  };

  const handleProfessionChange = (event) => {
    const value = event.target.value;
    setProfession(value);
    validateProfession(value);
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    setLocation(value);
    validateLocation(value);
  };

  const handleInterestChange = (event) => {
    const value = event.target.value;
    setCurrentInterest(value);
    if (containsEmoji(value)) {
      setInterestError('Interests cannot contain emojis.');
    } else {
      setInterestError('');
    }
  };

  const addInterest = (event) => {
    event.preventDefault();
    if (currentInterest.trim() && !interests.includes(currentInterest) && !containsEmoji(currentInterest)) {
      setInterests([...interests, currentInterest.trim()]);
      setCurrentInterest('');
      setInterestError('');
    }
  };
  


  return (
    <div className="user-info-container">
      <div className="user-info-box">
        <h1 className="title">Welcome!</h1>
        <p className="subtitle">Just a quick setup to finish registration...</p>
        <form onSubmit={handleSubmit} className="user-info-form">
          {/* Profile Picture Section */}
          <div className="profile-pic-container">
            <label>Profile Picture:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePicChange}
            />
            {profilePic && (
              <div className="profile-pic-preview">
                <img
                  src={URL.createObjectURL(profilePic)}
                  alt="Profile Preview"
                />
              </div>
            )}
          </div>

          {/* Username Field */}
          <div className="input-group">
            <label>Username*</label>
            <input
              type="text"
              value={username}
              onChange={(event) => setUsername(event.target.value.toLowerCase())}
              placeholder="Enter your username"
              onBlur={() => validateUsername(username)}
            />
            {usernameError && (
              <p className="error-message">{usernameError}</p>
            )}
            {usernameAvailability && (
              <p className={usernameAvailability.includes('available') ? 'success-message' : 'error-message'}>
                {usernameAvailability}
              </p>
            )}
          </div>

          {/* Interests Field */}
          <div className="input-group">
            <label>Interests:</label>
            <input
              type="search"
              value={currentInterest}
              onChange={handleInterestChange}
              placeholder="Add your interests"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  addInterest(event);
                }
              }}
            />
            {interestError && (
              <p className="error-message">{interestError}</p>
            )}
            <div className="interests-tags">
              {interests.map((interest, index) => (
                <span key={index} className="tag">
                  {interest}
                  <span
                    className="remove-tag"
                    onClick={() => removeInterest(interest)}
                  >
                    x
                  </span>
                </span>
              ))}
            </div>
          </div>

          {/* Profession Field */}
          <div className="input-group">
            <label>Profession:</label>
            <input
              type="text"
              value={profession}
              onChange={handleProfessionChange}
              placeholder="What's your profession?"
            />
            {professionError && (
              <p className="error-message">{professionError}</p>
            )}
          </div>

          {/* Updated Location Field */}
          <div className="input-group">
            <label>Location:</label>
            <input
              type="text"
              value={location}
              onChange={handleLocationChange}
              placeholder="Where are you located?"
            />
            {locationError && (
              <p className="error-message">{locationError}</p>
            )}
          </div>

          <button type="submit" className="submit-btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default UserInfo;
