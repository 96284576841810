import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import './UserProfile.scss';
import Menu from '../../icons/threeDots.png';
import { useUser } from '../../App';
import { formatFollowerCount } from '../../formatFollowerCount';
import axios from 'axios';
import ProfessionImg from '../../icons/job.png'
import LocationImg from '../../icons/location.png'
import ReportModal from '../../ReportModal';
import ShareModal from '../../ShareModal';
import { DomainUrl } from '../../config';
const Profile1 = '/DefaultImg/profileDefault.png';
const postDefaultImage = '/DefaultImg/postDefault.jpg';


const UserProfile = () => {
  const params = useParams();
  const username = params.id || null;
  const [userData, setUserData] = useState(null); 
  const [isPostMenuVisible, setIsPostMenuVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const menuRef = useRef(null);
  const currentUserId =  useUser(); 

  const initialIsFollowing = userData ? userData.isFollowing : false;
  const [isFollowing, setIsFollowing] = useState(initialIsFollowing);
  const [showUnfriendModal, setShowUnfriendModal] = useState(false);
  const navigate = useNavigate();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  
  const openReportModal = () => setIsReportModalOpen(true);
  const closeReportModal = () => setIsReportModalOpen(false);


  // Fetch user data from the backend
  useEffect(() => {
    const fetchUserData = async () => {
      if (!username || !currentUserId) {
        console.log('No username or currentUserId provided');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.get(`${DomainUrl}/api/users/getotherusers`, {
          params: {
            currentUserId, 
            username,
          }
        });
        
        if (response.status !== 200) {
          throw new Error('User not found');
        }

        const data = response.data;
        setUserData(data);

        // Redirect if the userId matches currentUserId
        if (data.user_id === currentUserId) {
          navigate('/profile');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [username, currentUserId, navigate]);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsPostMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <h1 className="error-messagee">⚠ User not found</h1>;
  }

  if (!userData) {
    return <h2 className="error-message">No user data available</h2>;
  }

  // Rest of the component remains the same...
  const interestsArray = Array.isArray(userData.interests)
    ? userData.interests
    : userData.interests?.split(',').map(item => item.trim()) || [];

  const togglePostMenu = () => {
    setIsPostMenuVisible((prev) => !prev);
  };

  const handleAddFriend = async () => {
    try {
      const userId = userData.user_id;

      // Send friend request
      const response = await axios.post(`${DomainUrl}/api/relationships/addFriend`, {
        currentUserId, // Current logged-in user's ID
        userId,        // The ID of the user to be followed
      });



      if (response.status === 200) {
        // Update state to reflect the friend status
        setIsFollowing(true);
        setUserData(prevData => ({ ...prevData, isFollowing: true, followerCount: prevData.followerCount + 1 }));
      } else {
        alert("Error adding friend please try again later.");
      }
    } catch (error) {
      console.error('Error adding friend:', error);
      alert("Failed to add friend.");
    }
  };

  const handleRemoveFriend = async () => {
    setShowUnfriendModal(true);
  };

  const confirmRemoveFriend = async () => {
    try {
      const response = await axios.post(`${DomainUrl}/api/relationships/removefriend`, {
        currentUserId,
        userId: userData.user_id
      });
      if (response.status === 200) {
        setUserData(prevData => ({ ...prevData, isFollowing: false, followerCount: prevData.followerCount - 1 }));
      }
    } catch (error) {
      console.error('Error removing friend:', error);
    } finally {
      setShowUnfriendModal(false);
    }
  };

  const handleBlockUser = async () => {
    try {
      const response = await axios.post(`${DomainUrl}/api/blocked/blockuser`, {
        currentUserId,
        userId: userData.user_id
      });

      if (response.status === 200) {
        alert('User has been blocked successfully.');
        // You might want to update the UI or redirect the user after blocking
      } else {
        alert('Failed to block user. Please try again.');
      }
    } catch (error) {
      console.error('Error blocking user:', error);
      alert('An error occurred while trying to block the user.');
    }
  };

  const displayImages = userData.latestPosts;

  const noContentMessage = "No posts yet";
  const defaultImage = postDefaultImage;

  const handleShareProfile = () => {
    setIsShareModalOpen(true);
    setIsPostMenuVisible(false);
  };

  const profileUrl = `${window.location.origin}/user/${userData.username}`;

  return (
    <div>
      <div className='profileContainerUP'>
        <div className='userDetailUP'>
          <img 
            src={userData.profile_picture ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${userData.profile_picture}` : Profile1} 
            className='profilePictureUP' 
            alt="Profile" 
          />
          <div className='userNameNBioUP'>
            <span className='userNameUP'>{userData.username}</span>
          </div>
          <img src={Menu} className='profMenuUP' onClick={togglePostMenu} alt="Menu" />
          {isPostMenuVisible && (
            <div className="post-menu-container" ref={menuRef}>
              <div className={`post-menu-options ${isPostMenuVisible ? 'visible' : ''}`}>
                <>
                  <button onClick={handleShareProfile}>
                    <span className="icon">⤴</span> Share profile
                  </button>

                  {/* <button onClick={handleBlockUser}>
                    <span className="icon">🚫</span> Block User
                  </button> */}
                  <button onClick={openReportModal}>
                    <span className="icon">🚩</span> Report User
                  </button>
                </>
              </div>
            </div>
          )}
        </div>

        <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        url={profileUrl}
        name={"Profile"}
        />

        <ReportModal
        isOpen={isReportModalOpen}
        onClose={closeReportModal}
        context="user"
        reportedId={userData.user_id}
        currentUserId={currentUserId}
        />

        <div className='userInterestUP'>
          {userData.interests && interestsArray.length > 0 && (
            interestsArray.map((interest, index) => (
              <span key={index}>#{interest}</span>
            ))
          )}
        </div>

        <div className='otherDetailUP'>
        {/* Render profession only if it exists and is not empty */}
        {userData.location && (
            <div className="locationUP">
              <img src={LocationImg} alt="Location" className="icon location-icon" />
              <span>{userData.location}</span>
            </div>
          )}

          {userData.profession && (
            <div className="professionUP">
              <img src={ProfessionImg} alt="Profession" className="icon profession-icon" />
              <span>{userData.profession}</span>
            </div>
          )}

          <div className="friendsListUP">
            <span>{formatFollowerCount(userData.followerCount)} friends</span>
          </div>
        
      </div>

      <div className='friendsAdd'>
      <span 
        
        onClick={userData.isFollowing ? handleRemoveFriend : handleAddFriend}
      >
        {userData.isFollowing ? "Friends ✔" : "Add friend +"}
      </span>
      
      {showUnfriendModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Unfriend</h2>
            <p>Are you sure you want to unfriend this user?</p>
            <button onClick={confirmRemoveFriend}>Yes, Unfriend</button>
            <button onClick={() => setShowUnfriendModal(false)}>Cancel</button>
          </div>
        </div>
      )}
 
      </div>



      <div className='userPostUP'>
          <div className='postHeadingUP'>
            <span className='postTextUP'>
            Posts ({formatFollowerCount(userData.postCount) || 0})
            </span>
          </div>
      </div>

        <div className='postGrid'>
      {(!displayImages || displayImages.length === 0) ? (
        <p className="no-content-message">{noContentMessage}</p>
      ) : (
        displayImages.map((imageArray, index) => (
          <Link to={`/post-list/${userData.username}`} key={index}>
            <img
              src={imageArray.length > 0 ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${imageArray[0]}` : defaultImage}
              alt={`'Post'} image ${index + 1}`}
              className='postImage'
            />
          </Link>
        ))
      )}
    </div>
      </div>
    </div>
  );
};

export default UserProfile;
