import React, { useState, useEffect } from 'react';
import './EditProfile.scss';
import { useUser } from '../../App'; // Assuming this gives the current user's ID
import axios from 'axios';
import { DomainUrl } from '../../config';

function EditProfile() {
  const currentUserId = useUser();
  const [userData, setUserData] = useState({
    username: '',
    profile_picture: '',
    interests: [],
    location: '',
    profession: '',
  });
  const [newInterest, setNewInterest] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [usernameError, setUsernameError] = useState('');
  const [usernameAvailability, setUsernameAvailability] = useState('');
  const [username, setUsername] = useState('');
  const [currentInterest, setCurrentInterest] = useState('');
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const [locationError, setLocationError] = useState('');
  const [professionError, setProfessionError] = useState('');
  const [interestError, setInterestError] = useState('');
  
  
// Remove the newInterest state, as we'll use currentInterest instead

useEffect(() => {
  const fetchUserData = async () => {
    if (!currentUserId) return;
    try {
      const response = await axios.get(`${DomainUrl}/api/users/getusers/${currentUserId}`);
      setUserData({
        ...response.data,
        interests: response.data.interests ? response.data.interests.split(',') : []
      });
      setUsername(response.data.username);
      if (response.data.profile_picture) {
        setProfilePicPreview(`https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${response.data.profile_picture}`);
      }
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch user data');
      setLoading(false);
    }
  };
  fetchUserData();
}, [currentUserId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (userData.username) {
        checkUsernameAvailability(userData.username);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [userData.username]);


  const containsEmoji = (text) => {
    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E0}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    return emojiRegex.test(text);
  };

  const handleInputChange = (field, value) => {
    if (field === 'username') {
      if (containsEmoji(value)) {
        setUsernameError('Username cannot contain emojis.');
        return;
      }
      validateUsername(value);
    } else if (field === 'location') {
      if (containsEmoji(value)) {
        setLocationError('Location cannot contain emojis.');
        return;
      }
      setLocationError('');
    } else if (field === 'profession') {
      if (containsEmoji(value)) {
        setProfessionError('Profession cannot contain emojis.');
        return;
      }
      setProfessionError('');
    }
    setUserData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];
    setUserData((prevData) => ({ ...prevData, profile_picture: file }));
    setProfilePicPreview(URL.createObjectURL(file));
  };

  const handleAddInterest = (event) => {
    event.preventDefault();
    if (currentInterest.trim() && !userData.interests.includes(currentInterest.trim())) {
      setUserData((prevData) => ({
        ...prevData,
        interests: [...prevData.interests, currentInterest.trim()],
      }));
      setCurrentInterest('');
    }
  };

  const handleInterestInputChange = (value) => {
    setCurrentInterest(value);
    if (containsEmoji(value)) {
      setInterestError('Interests cannot contain emojis.');
    } else {
      setInterestError('');
    }
  };

  const handleRemoveInterest = (interest) => {
    setUserData((prevData) => ({
      ...prevData,
      interests: prevData.interests.filter((item) => item !== interest),
    }));
  };

  const handleSaveChanges = async () => {
    if (!validateUsername(userData.username) || locationError || professionError || interestError) {
      alert('Please correct the errors before saving.');
      return;
    }
    try {
      const formData = new FormData();
      Object.keys(userData).forEach(key => {
        if (key === 'interests') {
          formData.append(key, userData[key].join(','));
        } else if (key === 'profile_picture' && userData[key] instanceof File) {
          formData.append(key, userData[key]);
        } else {
          formData.append(key, userData[key]);
        }
      });
  
      await axios.put(`${DomainUrl}/api/users/updateuser/${currentUserId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      alert('Profile updated successfully!');
    } catch (err) {
      alert('Failed to update profile.');
    }
  };



  const validateUsername = (name) => {
    const usernameRegex = /^[a-zA-Z0-9._]{3,15}$/;
    if (!usernameRegex.test(name)) {
      setUsernameError('Username must be 3-15 characters and contain only letters, numbers, underscores, and periods.');
      return false;
    }
    if (name.includes(' ')) {
      setUsernameError('Username cannot contain spaces.');
      return false;
    }
    if (name.startsWith('.') || name.endsWith('.')) {
      setUsernameError('Username cannot start or end with a period.');
      return false;
    }
    setUsernameError('');
    return true;
  };

  const checkUsernameAvailability = async (name) => {
    if (validateUsername(name)) {
      try {
        const response = await axios.get(`${DomainUrl}/api/auth/checkusername?username=${name}`);
        if (response.data.available) {
          setUsernameAvailability('Username is available');
        } else {
          setUsernameAvailability('Username is already taken');
        }
      } catch (error) {
        console.error('Error checking username availability:', error);
        setUsernameAvailability('');
      }
    } else {
      setUsernameAvailability('');
    }
  };


  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='editProfileCont'>
    <div className='editProfileWrap'>
      <span className='EPText'>Edit Profile</span>
      <div className='editProfPic'>
        <input
          type='file'
          id='profilePictureInput'
          style={{ display: 'none' }}
          accept='image/*'
          onChange={handleProfilePictureChange}
        />
        <img
          src={profilePicPreview || '/default-profile.png'}
          alt="Profile"
          onClick={() => document.getElementById('profilePictureInput').click()}
        />
        <span
          className='changeProfPicButton'
          onClick={() => document.getElementById('profilePictureInput').click()}
        >
          ✏
        </span>
      </div>
     
      <div className='edituserName'>
        <input
          type='text'
          value={userData.username}
          onChange={(e) => handleInputChange('username', e.target.value.toLowerCase())}
        />
        {usernameError && <p className="error">{usernameError}</p>}
        {usernameAvailability && <p className="availability">{usernameAvailability}</p>}
      </div>

      <div className='editInterest'>
          <div className='interestList'>
            {userData.interests.map((interest, index) => (
              <span key={index} className='interestItem'>
                {interest}
                <button onClick={() => handleRemoveInterest(interest)}>×</button>
              </span>
            ))}
          </div>
          <input 
            type="search" 
            value={currentInterest}
            onChange={(e) => handleInterestInputChange(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleAddInterest(event);
              }
            }}
            placeholder='Add new interest' 
          />
          {interestError && <p className="error">{interestError}</p>}
        </div>

      <div className='editLocation'>
        <input
          type='text'
          value={userData.location}
          onChange={(e) => handleInputChange('location', e.target.value)}
        />
        {locationError && <p className="error">{locationError}</p>}
      </div>

      <div className='editProfession'>
        <input
          type='text'
          value={userData.profession}
          onChange={(e) => handleInputChange('profession', e.target.value)}
        />
        {professionError && <p className="error">{professionError}</p>}
      </div>

      <div className='saveChangesBtn'>
        <button onClick={handleSaveChanges}>Save Changes</button>
      </div>
    </div>
  </div>
  );
}

export default EditProfile;

// Break point 1