import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Profile.scss';
import Menu from '../../icons/threeDots.png';
import { useUser } from '../../App';
import { formatFollowerCount } from '../../formatFollowerCount';
import ProfessionImg from '../../icons/job.png'
import LocationImg from '../../icons/location.png'
import { DomainUrl } from '../../config';
import ShareModal from '../../ShareModal';

const draftDefaultImage = '/DefaultImg/draftDefault.jpg';
const postDefaultImage = '/DefaultImg/postDefault.jpg';
const profileDefaultImage = '/DefaultImg/profileDefault.png';


const Profile = () => {
  const currentUserId = useUser();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDraft, setShowDraft] = useState(false);
  const [isPostMenuVisible, setIsPostMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUserId) {
        console.log("No user ID available yet");
        setLoading(false);
        return;
      }

      
      try {
        const response = await axios.get(`${DomainUrl}/api/users/getusers/${currentUserId}`);
        setUserData(response.data);
        

        setLoading(false);
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError('Failed to fetch user data: ' + (err.response ? `${err.response.status} ${err.response.data.message}` : err.message));
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUserId]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsPostMenuVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSignOut = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const handleClickFriends = () => {
    navigate('/friends');
  };

  const togglePostMenu = () => {
    setIsPostMenuVisible(prev => !prev);
  };

  if (!currentUserId) return <div>Loading user information...</div>;
  if (loading) return <div>Loading user data...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!userData) return <div>User not found</div>;

  const displayImages = showDraft ? userData.latestDrafts : userData.latestPosts;

  const noContentMessage = showDraft ? "No drafts yet" : "No posts yet";
  const defaultImage = showDraft ? draftDefaultImage : postDefaultImage;

  const handleShareProfile = () => {
    setIsShareModalOpen(true);
    setIsPostMenuVisible(false);
  };

  const profileUrl = `${window.location.origin}/profile/${userData.username}`;

  return (
    <div>
      <div className='profileContainer'>
        <div className='userDetail'>
        <img src={userData.profile_picture ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${userData.profile_picture}` : profileDefaultImage} 
        className='profilePicture' alt="Profile" />          
        <div className='userNameNBio'>
            <span className='userName'>{userData.username}</span>
          </div>
          <img src={Menu} className='profMenu' onClick={togglePostMenu} alt="Menu" />

          {isPostMenuVisible && (
            <div className="post-menu-container" ref={menuRef}>
              <div className={`post-menu-options ${isPostMenuVisible ? 'visible' : ''}`}>
                <button>
                  <Link to='/edit-profile' style={{ color: 'inherit', textDecoration: 'none' }}>
                    <span className="icon">✏️</span> Edit Profile
                  </Link>
                </button>
                <button>
                  <Link to='/acc-setting' style={{ color: 'inherit', textDecoration: 'none' }}>
                    <span className="icon">⚙</span> Settings
                  </Link>
                </button>
                <button onClick={handleShareProfile}>
                <span className="icon">⤴</span> Share profile
                </button>
                <button onClick={handleSignOut}>
                  <span className="icon">❗</span> Sign out
                </button>
              </div>
            </div>
          )}
        </div>

        <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        url={profileUrl}
        name={"Profile"}
        />

        {/* Render interests only if it is not an empty array or empty string */}
        {userData.interests && userData.interests.length > 0 && userData.interests !== "\"[]\"" && (
          <div className='userInterest'>
            {userData.interests.split(',').map((interest, index) => (
              <span key={index}>#{interest.trim()}</span>
            ))}
          </div>
        )}

        <div className='otherDetail'>
        {/* Render profession only if it exists and is not empty */}
        {userData.location && (
          <div className="location">
            <img src={LocationImg} alt="Location" className="icon location-icon" />
            <span>{userData.location}</span>
          </div>
        )}
        {userData.profession && (
          <div className="profession">
          <img src={ProfessionImg} alt="Profession" className="icon profession-icon" />
          <span>{userData.profession}</span>
        </div>
        )}

        {/* Friends section (always rendered) */}
        
          <span className='friendsList' onClick={handleClickFriends}>Friends</span>

        </div>

        <div className='userPost'>
          <div className='postHeading'>
            <span
              className={`postText ${!showDraft ? 'active' : ''}`}
              onClick={() => setShowDraft(false)}
            >
              Posts ({formatFollowerCount(userData.postCount) || 0})
            </span>
            <span
              className={`draftText ${showDraft ? 'active' : ''}`}
              onClick={() => setShowDraft(true)}
            >
              Draft ({formatFollowerCount(userData.draftCount) || 0})
            </span>
          </div>
        </div>

        <div className='postGrid'>
      {(!displayImages || displayImages.length === 0) ? (
        <p className="no-content-message">{noContentMessage}</p>
      ) : (
        displayImages.map((imageArray, index) => (
          <Link to={showDraft ? "/draft-list" : `/post-list/${userData.username}`} key={index}>
            <img
              src={imageArray.length > 0 ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${imageArray[0]}` : defaultImage}
              alt={`${showDraft ? 'Draft' : 'Post'} image ${index + 1}`}
              className='postImage'
            />
          </Link>
        ))
      )}
    </div>
      </div>
    </div>
  );
};

export default Profile;
