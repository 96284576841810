import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import CategoryModel from './CategoryModel';
import './Home.scss';
import { useUser } from '../../App';
import { DomainUrl } from '../../config';

const Home = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentUserId = useUser();
  const postsPerPage = 9; // Adjusted to match backend limit

  const fetchedPostIds = useRef(new Set());

  const fetchPosts = useCallback(async () => {
    if (!currentUserId) {
      console.warn("currentUserId is undefined, aborting fetch");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${DomainUrl}/api/home/gethomeposts`, {
        params: {
          userId: currentUserId,
          limit: postsPerPage,
          previousPostIds: Array.from(fetchedPostIds.current)
        }
      });

      const newPosts = response.data.posts || [];

      // Send new posts to backend to set views
      if (newPosts.length > 0) {
        try {
          await axios.post(`${DomainUrl}/api/posts/postviews`, {
            postIds: newPosts.map(post => post.post_id)
          });
        } catch (viewErr) {
          console.error('Error setting views:', viewErr);
        }
      }

      // Add new post IDs to fetchedPostIds
      newPosts.forEach(post => fetchedPostIds.current.add(post.post_id));

      setAllPosts(prevPosts => [...prevPosts, ...newPosts]);
      setTotalPages(response.data.totalPages || 1);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to fetch posts');
      setLoading(false);
    }
  }, [currentUserId, postsPerPage]);

  useEffect(() => {
    if (allPosts.length < currentPage * postsPerPage && currentPage === Math.ceil(allPosts.length / postsPerPage) + 1) {
      fetchPosts();
    }
  }, [fetchPosts, allPosts.length, currentPage, postsPerPage]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * postsPerPage;
    const endIndex = startIndex + postsPerPage;
    setVisiblePosts(allPosts.slice(startIndex, endIndex));
  }, [currentPage, allPosts, postsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    const totalPagesCalculated = Math.ceil(allPosts.length / postsPerPage);
    const effectiveTotalPages = Math.max(totalPagesCalculated, totalPages);

    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(effectiveTotalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="paginationn">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Previous
        </button>
        {startPage > 1 && <span className="pagination-ellipsis">...</span>}
        {pageNumbers}
        {endPage < effectiveTotalPages && <span className="pagination-ellipsis">...</span>}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === effectiveTotalPages && allPosts.length < effectiveTotalPages * postsPerPage}
          className="pagination-button"
        >
          Next
        </button>
      </div>
    );
  };

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className='CLSS'>
      {visiblePosts && visiblePosts.length > 0 ? (
        <>
          <CategoryModel posts={visiblePosts} />
          {loading && <div className="loading-message">Loading posts...</div>}
          {!loading && visiblePosts.length > 0 && renderPagination()}
        </>
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
};

export default Home;