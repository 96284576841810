import React, { useState, useEffect  } from 'react';
import './NavBar.scss';
import { Link, useLocation } from 'react-router-dom';

import HomeIcon from '../../icons/Home.png';
import HomeIconActive from '../../icons/HomeActive.png';
import Saved from '../../icons/bookmark.png';
import SavedIconActive from '../../icons/bookmarkednav.png';
import ExploreIcon from '../../icons/Explore.png';
import ExploreIconActive from '../../icons/ExploreActive.png';
import ProfileIcon from '../../icons/profileDefault.png';
import CreateIcon from '../../icons/write.png';
import CreateIconActive from '../../icons/writeActive.png';

const NavBar = () => {
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    let newActiveLink = '';

    switch (currentPath) {
      case '/':
        newActiveLink = 'home';
        break;
      case '/saved':
        newActiveLink = 'saved';
        break;
      case '/explore':
        newActiveLink = 'explore';
        break;
      case '/profile':
        newActiveLink = 'profile';
        break;
      case '/create-post':
        newActiveLink = 'createPost';
        break;
      default:
        newActiveLink = '';
    }

    setActiveLink(newActiveLink);
  }, [location]);

  return (
    <div className='navBar'>
      <div className='container'>
      <div className='home'>
          <Link to={'/'} onClick={() => setActiveLink('home')}>
            <img
              src={activeLink === 'home' ? HomeIconActive : HomeIcon}
              alt='Home Icon'
              className='homeIcon'
            />
            <a
              style={{
                color: activeLink === 'home' ? '#ff9b58' : '',
              }}
            >
              Home
            </a>
          </Link>
        </div>
        <div className='explore'>
          <Link to={'/explore'} onClick={() => setActiveLink('explore')}>
            <img
              src={activeLink === 'explore' ? ExploreIconActive : ExploreIcon}
              alt='Explore Icon'
              className='exploreIcon'
            />
            <a
              style={{
                color: activeLink === 'explore' ? '#ff9b58' : '',
              }}
            >
              Explore
            </a>
          </Link>
        </div>
        <div className='saved'>
          <Link to={'/saved'} onClick={() => setActiveLink('saved')}>
            <img
              src={activeLink === 'saved' ? SavedIconActive : Saved}
              alt='Saved Icon'
              className='savedIcon'
            />
            <a
              style={{
                color: activeLink === 'saved' ? '#ff9b58' : '',
              }}
            >
              Saved
            </a>
          </Link>
        </div>
        <div className='profile'>
          <Link to={'/profile'} onClick={() => setActiveLink('profile')}>
            <img
              src={ProfileIcon}
              alt='Profile Icon'
              className='profileIcon'
            />
            <a
              style={{
                color: activeLink === 'profile' ? '#ff9b58' : '',
              }}
            >
              Profile
            </a>
          </Link>
        </div>
        <div className='createPost'>
          <Link to={'/create-post'} onClick={() => setActiveLink('createPost')}>
            <img
              src={activeLink === 'createPost' ? CreateIconActive : CreateIcon}
              alt='Create post Icon'
              className='createIcon'
            />
            <a
              style={{
                color: activeLink === 'createPost' ? '#ff9b58' : '',
              }}
            >
              Create Post
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
