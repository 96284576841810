import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Search from '../../icons/Search.png';
import SearchActive from '../../icons/SearchActive.png';
import './Header.scss';

const Header = () => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/search') {
      setIsSearchActive(true);
    } else {
      setIsSearchActive(false);
    }
  }, [location.pathname]);

  return (
    <div className='header'>
      <Link to="/">
        <img src='/HeyBruhlogo.png' alt='HeyBruh! Logo' className='logo'></img>
      </Link>
      <Link to="/search">
        <img
          src={isSearchActive ? SearchActive : Search}
          alt='Search icon'
          className='searchIcn'
        />
      </Link>
    </div>
  );
};

export default Header;