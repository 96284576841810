import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useUser } from '../../App';
import './FriendsList.scss';
import { DomainUrl } from '../../config';

const profileDefaultImage = '/DefaultImg/profileDefault.png';

const FriendsList = () => {
  const currentUserID = useUser();
  const [activeTab, setActiveTab] = useState('followers');
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState({ followers: 1, following: 1 });
  const [hasMore, setHasMore] = useState({ followers: true, following: true });
  const ITEMS_PER_PAGE = 10;
  const [showUnfriendModal, setShowUnfriendModal] = useState(false);
  const [friendToRemove, setFriendToRemove] = useState(null); // Track the friend to remove

  const fetchList = async (type, pageNum) => {
    if (!currentUserID) {
      setError('User ID is not available. Please ensure you are logged in.');
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${DomainUrl}/api/relationships/getfollowerslist`, {
        params: { 
          userId: currentUserID, 
          type: type === 'followers' ? 'friends' : 'friendsWith',
          page: pageNum,
          limit: ITEMS_PER_PAGE
        }
      });

      if (Array.isArray(response.data)) {
        const newItems = response.data;
        if (type === 'followers') {
          setFollowers(prev => pageNum === 1 ? newItems : [...prev, ...newItems]);
        } else {
          setFollowing(prev => pageNum === 1 ? newItems : [...prev, ...newItems]);
        }
        setHasMore(prev => ({
          ...prev,
          [type]: newItems.length === ITEMS_PER_PAGE
        }));
        setPage(prev => ({
          ...prev,
          [type]: pageNum
        }));
      } else {
        throw new Error('Unexpected response format from server.');
      }
    } catch (err) {
      console.error(`Error fetching ${type} list:`, err);
      setError(`Failed to fetch ${type} list. Please try again later.`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchList('followers', 1);
    fetchList('following', 1);
  }, [currentUserID]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const loadMore = () => {
    if (!loading && hasMore[activeTab]) {
      fetchList(activeTab, page[activeTab] + 1);
    }
  };

  const handleRemove = async () => {
    if (activeTab === 'following') {
      confirmRemoveFriendWith(friendToRemove);
    } else {
      confirmRemoveFriend(friendToRemove);
    }
  };

  const handleRemoveFriend = (friendId) => {
    setFriendToRemove(friendId); // Set the friend to remove
    setShowUnfriendModal(true); // Open the modal
  };

  const confirmRemoveFriendWith = async (friendId) => {
    if (!currentUserID) {
      console.error('currentUserID is undefined');
      return;
    }

    try {
      const response = await axios.post(`${DomainUrl}/api/relationships/removefriend`, {
        currentUserId: currentUserID,
        userId: friendId
      });

      if (response.status === 200) {
        // Remove the friend from the 'following' list
        setFollowing((prevFollowing) => 
          prevFollowing.filter(friend => friend.user_id !== friendId)
        );
      }
      
    } catch (error) {
      console.error('Error removing friend:', error);
    } finally {
      setShowUnfriendModal(false);
      setFriendToRemove(null); // Clear the friend to remove after action
    }
  };

  const confirmRemoveFriend = async (friendId) => {
    if (!currentUserID) {
      console.error('currentUserID is undefined');
      return;
    }

    try {
      const response = await axios.post(`${DomainUrl}/api/relationships/removefriendwith`, {
        currentUserId: currentUserID,
        userId: friendId
      });

      if (response.status === 200) {
        // Remove the friend from the 'followers' list
        setFollowers((prevFollowing) => 
          prevFollowing.filter(friend => friend.user_id !== friendId)
        );
      }
      
    } catch (error) {
      console.error('Error removing friend:', error);
    } finally {
      setShowUnfriendModal(false);
      setFriendToRemove(null); // Clear the friend to remove after action
    }
  };

  const renderList = () => {
    const list = activeTab === 'followers' ? followers : following;
    return list.map((friend) => (
      <div className="friendItem" key={friend.user_id}>
        <Link to={`/user/${friend.username}`} className='userLink'>
          <img src={friend.profile_picture ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${friend.profile_picture}` : profileDefaultImage} alt={friend.username} />
          <span>{friend.username}</span>
        </Link>
        <button className="removeBtn" onClick={() => handleRemoveFriend(friend.user_id)}>
          Remove
        </button>
      </div>
    ));
  };

  return (
    <div className="friendsContainer">
      <div className="friendsWrap">
        <div className="selectfrndBtn">
          <button
            onClick={() => handleTabChange('followers')}
            className={activeTab === 'followers' ? 'active' : ''}
          >
            Friends
          </button>
          <button
            onClick={() => handleTabChange('following')}
            className={activeTab === 'following' ? 'active' : ''}
          >
            Friends With
          </button>
        </div>
        {error && <p className="error">{error}</p>}
        <div className="frndsList">
          {renderList()}
        </div>
        {loading && <p>Loading...</p>}
        {!loading && hasMore[activeTab] && (activeTab === 'followers' ? followers : following).length > 0 && (
          <button onClick={loadMore} className="loadMoreBtn">Load More</button>
        )}
        {!loading && !hasMore[activeTab] && (activeTab === 'followers' ? followers : following).length > 0 && (
          <p>No more friends to load.</p>
        )}
        {!loading && (activeTab === 'followers' ? followers : following).length === 0 && (
          <p>{activeTab === 'followers' ? 'No friends yet.' : 'You\'re not friends with anyone yet.'}</p>
        )}
      </div>

      {showUnfriendModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Unfriend</h2>
            <p>Are you sure you want to unfriend this user?</p>
            <button onClick={handleRemove}>Yes, Unfriend</button>
            <button onClick={() => setShowUnfriendModal(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FriendsList;


// Break point