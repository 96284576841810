import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './CategoryPost.scss';
import Like from '../../icons/like.png';
import LikeActive from '../../icons/likeActive.png';
import Views from '../../icons/views.png';
import Share from '../../icons/share.png';
import Save from '../../icons/bookmark.png';
import SaveActive from '../../icons/bookmarkedD.png';
import moment from 'moment';
import { useUser } from '../../App';
import { formatFollowerCount } from '../../formatFollowerCount';
import ShareModal from '../../ShareModal';
import { DomainUrl } from '../../config';

const draftDefaultImage = '/DefaultImg/profileDefault.png';
const postDefaultImage = '/DefaultImg/postDefault.jpg';

const CategoryPost = ({
  postId,
  userId,
  userName = "Anonymous", // Default username
  userProfPic = "defaultProfilePic.jpg", // Default profile picture
  postImages = [], // Default to empty array for post images
  postTitle = "Untitled", // Default title
  postContent = "No content available.", // Default content
  likes = 0, // Default to 0 likes
  views = 0, // Default to 0 views
  time = "Just now", // Default time value
  isLiked = false, // Passed as a prop to manage like status
  isSaved = false, // Passed as a prop to manage save status
}) => {

  const currentUserId = useUser();
  const navigate = useNavigate();

  const [liked, setLiked] = useState(isLiked);  // Controlled by prop
  const [saved, setSaved] = useState(isSaved);  // Controlled by prop
  const [likeCount, setLikeCount] = useState(likes);

  const likeIcon = liked ? LikeActive : Like;
  const saveIcon = saved ? SaveActive : Save;

  const MAX_TITLE_LENGTH = 50;
  const MAX_CONTENT_LENGTH = 200;

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isPostMenuVisible, setIsPostMenuVisible] = useState(false);

  const truncatedTitle = postTitle.length > MAX_TITLE_LENGTH
    ? `${postTitle.substring(0, MAX_TITLE_LENGTH)}...`
    : postTitle;

  const truncatedContent = postContent.length > MAX_CONTENT_LENGTH
    ? `${postContent.substring(0, MAX_CONTENT_LENGTH)}...`
    : postContent;

  // Handle like action with immediate UI feedback
  const handleLikeClick = async () => {
    setLiked(!liked);
    setLikeCount(liked ? likeCount - 1 : likeCount + 1);

    try {
      const response = await fetch(`${DomainUrl}/api/likes/likepost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: postId,
          userId: currentUserId,
        }),
      });

      if (!response.ok) {
        // Rollback UI in case of an error
        setLiked(liked);
        setLikeCount(liked ? likeCount + 1 : likeCount - 1);
        console.error('Failed to like/unlike the post');
      }
    } catch (error) {
      // Rollback UI in case of an error
      setLiked(liked);
      setLikeCount(liked ? likeCount + 1 : likeCount - 1);
      console.error('Error liking/unliking the post:', error);
    }
  };

  // Handle save action with immediate UI feedback
  const handleSaveClick = async () => {
    setSaved(!saved);

    try {
      const response = await fetch(`${DomainUrl}/api/saved/savepost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: postId,
          userId: currentUserId,
        }),
      });

      if (!response.ok) {
        // Rollback UI in case of an error
        setSaved(saved);
        console.error('Failed to save/unsave the post');
      }
    } catch (error) {
      // Rollback UI in case of an error
      setSaved(saved);
      console.error('Error saving/unsaving the post:', error);
    }
  };

  const handleShareProfile = () => {
    setIsShareModalOpen(true);
    setIsPostMenuVisible(false);
  };

  const profileUrl = `${window.location.origin}/post/${postId}`;

  return (
    <div className="postCP">
      <div className='postElementCP'>
        <Link to={`/post/${postId}`} state={{ postId }} style={{ color: 'inherit', textDecoration: 'none' }}>
          <div className='wrapCP'>
            {/* Ensure default post image is displayed if no image is provided */}
            <img 
              src={postImages.length > 0 ? `https://heybruh.nyc3.digitaloceanspaces.com/uploads/posts/${postImages[0]}` : postDefaultImage} 
              alt={postTitle} 
              className='postImgCP' 
            />
            <div className='profDetailsCP'>
              {/* Ensure default profile picture is displayed if none is provided */}
              <img 
                src={userProfPic ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${userProfPic}` : draftDefaultImage} 
                alt={userName} 
                className='profPicCP' 
              />
              <p className='userNameCP'>{userName}</p>
              <p className='timeCP'>{moment(time).fromNow()}</p>
            </div>
          </div>

          <div className='textContentCP'>
          <h1 className='postTitleCP'>
          {(() => {
              try {
                return decodeURIComponent(truncatedTitle);
              } catch (error) {
                console.error('Error decoding URI:', error);
                return truncatedTitle; // fallback to original if decoding fails
              } finally {
                
                // Only return postTitle if it exceeds the maximum length
                if (postTitle.length > MAX_TITLE_LENGTH) {
                  return decodeURIComponent(postTitle);
                }
              }
            })()}

          </h1>   
          
          <p className='postContentCP' dangerouslySetInnerHTML={{ __html: decodeURIComponent(truncatedContent) }} />

          {/* <p className='postContentCP' dangerouslySetInnerHTML={{
            __html: (() => {
              try {
                return decodeURIComponent(truncatedContent);
              } catch (error) {
                console.error('Error decoding URI:', error);
                return truncatedContent; // fallback to original if decoding fails
              } finally {
                // Only return postContent if it exceeds the maximum length
                if (postContent.length > MAX_CONTENT_LENGTH) {
                  return decodeURIComponent(postContent);
                }
              }
            })()
          }} /> */}
          </div>
        </Link>

        <div className='postFooterCP'>
          <div className='likeDivCP' onClick={handleLikeClick}>
            <img src={likeIcon} alt="like" className='likeIconCP' />
            <p className='likesCountCP'>{formatFollowerCount(likeCount)}</p>
          </div>

          <div className='viewsDivCP'>
            <img src={Views} alt="views" className='viewsIconCP' />
            <p className='viewsCountCP'>{formatFollowerCount(views)}</p>
          </div>

          <img src={Share} alt='Share Icon' onClick={handleShareProfile} className='shareCP' />

          <div className='saveDivCP' onClick={handleSaveClick}>
            <img src={saveIcon} alt='Save Icon' className='saveCP' />
          </div>
        </div>
      </div>

      <ShareModal
                    isOpen={isShareModalOpen}
                    onClose={() => setIsShareModalOpen(false)}
                    url={profileUrl}
                    name={"Post"}
                    />
    </div>
  );
};

export default CategoryPost;
// BreakPoint