import React, { useState } from 'react';
import "./Login.scss";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DomainUrl } from '../../config';

const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const logo = "/logo512.png";
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post(`${DomainUrl}/api/auth/login`, {  
        identifier: usernameOrEmail,
        password: password
      });

      const token = response.data.token;
      localStorage.setItem('token', token);
      
      window.location.href = '/';
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data);
      } else {
        setError('Something went wrong. Please try again.');
      }
    }
  };

  const handleForgotPassword = () => {
    if (!usernameOrEmail) {
      setError('Please enter your email address to reset your password.');
      return;
    }

    if (!isValidEmail(usernameOrEmail)) {
      setError('Please enter a valid email address to reset your password.');
      return;
    }

    // Navigate to ResetPassword component with email as state
    navigate('/reset-password', { state: { email: usernameOrEmail } });
  };

  const isValidEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login">
      <div className="card">
        <img src={logo} alt='Logo' className='logo' />
        <h1>Login</h1>
        <form className='form' onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username or Email"
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
          />
          <>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="show-password" onClick={toggleShowPassword}>
              {showPassword ? "Hide" : "Show"} password
            </span>
          </>
          {error && <p className="error">{error}</p>}
          <button className='login-btn'>Login</button>
          <a className='forgot-psw' onClick={handleForgotPassword}>Forgot password?</a>
        </form>
        <div className="divider">-or-</div>
        <p>Don't have an account?</p>
        <Link to="/register">
          <button className='register-btn'>Register</button>
        </Link>
      </div>
    </div>
  );
};

export default Login;