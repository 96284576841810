import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './SinglePost.scss';
import moment from 'moment';
import axios from 'axios';
import { DomainUrl } from '../../config';
import { useUser } from '../../App';
import ReportModal from '../../ReportModal';
import { formatFollowerCount } from '../../formatFollowerCount';
import CommentsPage from './Comments';
import PostMenuIcon from '../../icons/threeDots.png';
import LikeIcon from '../../icons/like.png';
import LikeActiveIcon from '../../icons/likeActive.png';
import SaveIcon from '../../icons/bookmark.png';
import SaveActiveIcon from '../../icons/bookmarkedD.png';
import CommentsIcon from '../../icons/comments.png';
import ShareIcon from '../../icons/share.png';
import ViewsIcon from '../../icons/views.png';
import commentsOffIcon from '../../icons/commentsoff.png';
import ShareModal from '../../ShareModal';

const profileDefaultImage = '/DefaultImg/profileDefault.png';

const SinglePost = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const param = useParams();
    const postId = location.state ? location.state.postId : param.id;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [scale, setScale] = useState(1);
    const touchStart = useRef(null);
    const touchEnd = useRef(null);
    const lastPinchDistance = useRef(null);
    const [isToolbarVisible, setIsToolbarVisible] = useState(true);
    const [isToolbarExpanded, setIsToolbarExpanded] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const from = 'SinglePost';
    const currentUserId =  useUser(); 
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [isPostMenuVisible, setIsPostMenuVisible] = useState(false);
    const menuRef = useRef(null);

    const [commentcount, setCommentCount] = useState(0);
    const [likes, setLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isComment, setIsComment] = useState(1);

    const [title, setTitle] = useState('');
    const [category, setDataCategory] = useState('');
    const [content, setContent] = useState('');
    const [post_id, setPostId] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  
    const openReportModal = () => setIsReportModalOpen(true);
    const closeReportModal = () => setIsReportModalOpen(false);

    const [isDarkTheme, setIsDarkTheme] = useState(() => {
      const savedTheme = localStorage.getItem('theme');
      return savedTheme ? JSON.parse(savedTheme) : true;
  });
  const [fontSize, setFontSize] = useState(() => {
      const savedFontSize = localStorage.getItem('fontSize');
      return savedFontSize ? parseInt(savedFontSize) : 18;
  });

  let decodedContent;

    try {
        // Try to decode the content
        decodedContent = decodeURIComponent(content);
    } catch (error) {
        console.warn('Error decoding content:', error);
        // If there's an error, fall back to the original content
        decodedContent = content;
    }

    // UseEffects

    useEffect(() => {
        const fetchPost = async () => {
          if (!currentUserId) {
            console.warn("currentUserId is undefined, aborting fetch");
            return;
          }
      
          try {
            
            const response = await axios.get(`${DomainUrl}/api/posts/retrievePost/${postId}`, {
              params: {
                userId: currentUserId,
              },
            });
            
            setPost(response.data);
            setLikes(response.data.like_count);
            setCommentCount(response.data.comment_count);
            setIsLiked(response.data.is_liked);
            setIsSaved(response.data.is_saved);
            setIsComment(response.data.comment_status);
            setPostId(response.data.post_id);
            setTitle(decodeURIComponent(response.data.title))
            setDataCategory(decodeURIComponent(response.data.category))
            setContent(decodeURIComponent(response.data.content))
            setLoading(false);
          } catch (err) {
            console.error('Error fetching post:', err); // Log error details
            setError('Failed to fetch post');
            setLoading(false);
          }
        };
      
        if (postId) {
          fetchPost();
        }
      }, [postId, currentUserId]); 
      
      

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsPostMenuVisible(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isViewerOpen) {
                closeImageViewer();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isViewerOpen]);

    useEffect(() => {
        const handleScroll = () => {
            setIsToolbarVisible(window.pageYOffset === 0);
            setIsToolbarExpanded(false);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
      localStorage.setItem('theme', JSON.stringify(isDarkTheme));
  }, [isDarkTheme]);

  useEffect(() => {
      localStorage.setItem('fontSize', fontSize.toString());
  }, [fontSize]);

    // implements
    
      const toggleTheme = () => {
        setIsDarkTheme(prev => !prev);
    };

    const increaseFontSize = () => setFontSize(prev => Math.min(prev + 1, 24));
    const decreaseFontSize = () => setFontSize(prev => Math.max(prev - 1, 12));
    const toolbarClass = `toolbar ${isToolbarVisible ? 'visible' : ''} ${isToolbarExpanded ? 'expanded' : ''}`;

    const toggleComments = () => {
        setShowComments(!showComments);
    };

    if (!postId) {
        return (
            <div>
                <p>No post ID provided</p>
                <button onClick={() => navigate(-1)}>Go Back</button>
            </div>
        );
    }

    // post = Object.values(Posts).flat().find(p => String(p.id) === String(postId));
    if (!post) {
        return (
            <div>
                <p>Post not found (ID: {postId})</p>
                <button onClick={() => navigate(-1)}>Go Back</button>
            </div>
        );
    }


    if (!post.user_id) {
        return (
            <div>
                <p>User not found for this post (User ID: {post.userId})</p>
                <button onClick={() => navigate(-1)}>Go Back</button>
            </div>
        );
    }


    // methods

    const handleSwipe = (direction) => {
        if (direction === 'left' && currentImageIndex < post.images.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
        } else if (direction === 'right' && currentImageIndex > 0) {
            setCurrentImageIndex(currentImageIndex - 1);
        }
    };

    const handleTouchStart = (e) => {
        if (e.touches.length === 2) {
            lastPinchDistance.current = getPinchDistance(e.touches);
        } else {
            touchStart.current = e.touches[0].clientX;
        }
    };

    const handleTouchMove = (e) => {
        if (e.touches.length === 2) {
            const distance = getPinchDistance(e.touches);
            const scaleFactor = distance / lastPinchDistance.current;
            let newScale = scale * scaleFactor;
            newScale = Math.min(Math.max(0.5, newScale), 3);
            setScale(newScale);
            lastPinchDistance.current = distance;
        } else {
            touchEnd.current = e.touches[0].clientX;
        }
    };

    const handleTouchEnd = () => {
        if (touchStart.current && touchEnd.current) {
            if (touchStart.current - touchEnd.current > 75) {
                handleSwipe('left');
            }
            if (touchEnd.current - touchStart.current > 75) {
                handleSwipe('right');
            }
        }
        touchStart.current = null;
        touchEnd.current = null;
        lastPinchDistance.current = null;
    };

    const getPinchDistance = (touches) => {
        return Math.hypot(
            touches[0].clientX - touches[1].clientX,
            touches[0].clientY - touches[1].clientY
        );
    };

    const openImageViewer = () => {
        setIsViewerOpen(true);
        setScale(1);
    };

    const closeImageViewer = () => {
        setIsViewerOpen(false);
        setScale(1);
    };

    const handleZoom = (event) => {
        event.preventDefault();
        let newScale = scale + event.deltaY * -0.01;
        newScale = Math.min(Math.max(0.5, newScale), 3);
        setScale(newScale);
    };

    const togglePostMenu = () => {
        setIsPostMenuVisible(prev => !prev);
    };

    const handleLike = async () => {
        
        try {
          const response = await fetch(`${DomainUrl}/api/likes/likepost`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              postId: postId,
              userId: currentUserId,
            }),
          });
    
          if (response.ok) {
            const updatedLikes = isLiked ? likes - 1 : likes + 1;
            setLikes(updatedLikes);
            setIsLiked(!isLiked);
          } else {
            console.error('Failed to like/unlike the post');
          }
        } catch (error) {
          console.error('Error liking/unliking the post:', error);
        }
      };


    const handleSave = async () => {
        
        try {
          const response = await fetch(`${DomainUrl}/api/saved/savepost`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              postId: postId,
              userId: currentUserId,
            }),
          });
    
          if (response.ok) {
            setIsSaved(!isSaved);
          } else {
            console.error('Failed to save/unsave the post');
          }
        } catch (error) {
          console.error('Error liking/unliking the post:', error);
        }
      };

      const handleCommentOff = async () => {
        try {
          const response = await fetch(`${DomainUrl}/api/posts/commentstatus`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              postId: postId,
              // Toggle the commentStatus value between 1 (true) and 0 (false)
              commentStatus: isComment === 1 ? 0 : 1,
            }),
          });
      
          if (response.ok) {
            console.log('Comment status updated successfully');
            setIsComment(isComment === 1 ? 0 : 1);
          } else {
            console.error('Failed to change the comment status');
          }
        } catch (error) {
          console.error('Error changing the status of the comment:', error);
        }
      };


      const handleDeleteClick = () => {
        setShowDeleteModal(true);
      };
    
      const handleDeletePost = async () => {
        try {
          const response = await fetch(`${DomainUrl}/api/posts/deletepost`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              postId: postId,
              userId: currentUserId,
            }),
          });
     
          if (response.ok) {
            console.log('Post deleted successfully');
            if (window.history.length > 1) {
              window.history.back();
            } else {
              window.location.href = '/';
            }
          } else {
            console.error('Failed to delete the post');
          }
        } catch (error) {
          console.error('Error deleting the post:', error);
        }
        setShowDeleteModal(false);
      };
      
    
      let CommentIcon = isComment === 1 ? CommentsIcon : commentsOffIcon;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!post) return <div>Post not found</div>;

    const handleShareProfile = () => {
      setIsShareModalOpen(true);
      setIsPostMenuVisible(false);
    };
  
    const profileUrl = `${window.location.origin}/post/${post.post_id}`;


    return (
        <div className={`single-post ${isDarkTheme ? 'dark-theme' : 'light-theme'}`} style={{ fontSize: `${fontSize}px` }}>
            <div className="post-header">
                <div className="user-info">

                    <Link to={`/user/${post.username}`} className="user-link">
                            <img 
                                src={ post.profile_picture ?  `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/profile/${post.profile_picture}` : profileDefaultImage}
                                alt={post.username} 
                                className="user-avatar" 
                            />
                            <p className="user-name">{post.username}</p>
                    </Link>

                    <span className='post-time'>
                    {moment(post.created_at).fromNow()}
                    </span>

                    <img src={PostMenuIcon} className="postMenu" onClick={togglePostMenu} alt="Menu Icon" />
                    {isPostMenuVisible && (
                        <div className="post-menu-container" ref={menuRef}>
                        <div className={`post-menu-options ${isPostMenuVisible ? 'visible' : ''}`}>
                        {currentUserId === post.user_id ? (
                            <>
                                <button>
                                <Link to={`/edit-post/${from}/${postId}`} state={{ postId, from }} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    <span className="icon">✏️</span> Edit Post
                                </Link>
                                </button>

                                <button onClick={handleShareProfile}>
                                <span className="icon">⤴</span> Share post
                                </button>
                                {/* <button onClick={() => alert('Archive  Post')}>
                                    <span className="icon">📥</span> Archive Post
                                </button> */}
                                <button onClick={handleCommentOff}>
                                <span className="icon">💭</span> {isComment === 1 ? 'Turn off' : 'Turn on'} comment
                                </button>
                                <button onClick={handleDeleteClick}>
                                    <span className="icon">❌</span> Delete Post
                                </button>
                            </>
                        ) : (
                          <>
                            <button onClick={openReportModal}>
                                <span className="icon">🚩</span> Report Post
                            </button>
                          </>
                        )}
                    </div>

                    </div>
                    )}
                </div>
                <h1 className="post-title">{decodeURIComponent(title)}</h1>
            </div>
                <ShareModal
                isOpen={isShareModalOpen}
                onClose={() => setIsShareModalOpen(false)}
                url={profileUrl}
                name={"Post"}
                />

                <ReportModal
                isOpen={isReportModalOpen}
                onClose={closeReportModal}
                context="post"
                reportedId={post_id}
                currentUserId={currentUserId}
                />
            <div 
                className="post-image-gallery"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="image-container" style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}>
                    {post.images.map((img, index) => (
                        <img 
                            key={index} 
                            src={`https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${img}`} 
                            alt={`Post image ${index + 1}`} 
                            className="post-image" 
                            onClick={openImageViewer}
                        />
                    ))}
                </div>
                <button className="swipe-button left" onClick={() => handleSwipe('right')} disabled={currentImageIndex === 0}>❮</button>
                <button className="swipe-button right" onClick={() => handleSwipe('left')} disabled={currentImageIndex === post.images.length - 1}>❯</button>
            </div>
            {showDeleteModal && (
                                    <div className="modal">
                                    <div className="modal-content">
                                        <h2>Confirm Delete</h2>
                                        <p>Are you sure you want to delete this post?</p>
                                        <button onClick={handleDeletePost}>Yes, Delete</button>
                                        <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
                                    </div>
                                    </div>
                                )}
            {isViewerOpen && (
                <div className="image-viewer" onClick={closeImageViewer}>
                    <div className="image-viewer-content" 
                         onClick={(e) => e.stopPropagation()}
                         onTouchStart={handleTouchStart}
                         onTouchMove={handleTouchMove}
                         onTouchEnd={handleTouchEnd}>
                        <img 
                            src={`https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${post.images[currentImageIndex]}`} 
                            style={{ transform: `scale(${scale})` }}
                            onWheel={handleZoom}
                        />
                        <button className="close-viewer" onClick={closeImageViewer}>✕</button>
                    </div>
                </div> 
            )}
            <div className="post-content" 
                dangerouslySetInnerHTML={{ __html: decodedContent  }}>
            </div>

            <div className="post-stats">
            <span>
                <img
                src={isLiked ? LikeActiveIcon : LikeIcon} // conditionally render icon
                alt="Like"
                onClick={handleLike} // trigger like/unlike on click
                style={{ cursor: 'pointer' }} // make the icon clickable
                />
                {formatFollowerCount(likes)} {/* Display the number of likes */}
            </span>
            <span>
                <img src={ViewsIcon} alt="Views" /> 
                {post.views}
            </span>
            <span
            onClick={isComment === 1 ? toggleComments : null} // Disable onClick if comments are off
            style={{ cursor: isComment === 1 ? 'pointer' : 'default', opacity: isComment === 1 ? 1 : 0.5 }} // Change cursor and style based on comment status
            >
            <img src={CommentIcon} alt="Comments" />
            
            {isComment === 1 && ( // Only show comment count if comments are enabled
                <>
                {formatFollowerCount(commentcount)}
                {post.comments}
                </>
            )}
            </span>

            <span onClick={handleShareProfile}>
            <img src={ShareIcon} alt="Share" /> 
            </span>
            <span onClick={handleSave}>
                <img
                src={isSaved ? SaveActiveIcon : SaveIcon} // conditionally render icon
                alt="Save"
                onClick={handleSave} // trigger like/unlike on click
                style={{ cursor: 'pointer' }} // make the icon clickable
                />
            </span>
        </div>

        {showComments && (
                <div className="comments-modal">
                    <div className="comments-modal-content">
                        <button className="close-comments" onClick={toggleComments}>✕</button>
                        <CommentsPage postId={postId} />
                    </div>
                </div>
            )}
        
            <button className="back-button" onClick={() => navigate(-1)}>Go Back</button>

            <div className={toolbarClass}>
                <button className="tool-button" onClick={() => setIsToolbarExpanded(prev => !prev)}>🔧</button>
                <div className="tool-options">
                    <button onClick={increaseFontSize}>A+</button>
                    <button onClick={decreaseFontSize}>A-</button>
                    <button onClick={toggleTheme}>{isDarkTheme ? '☀️' : '🌙'}</button>
                </div>
            </div>
        </div>
    );
};

export default SinglePost;
