import React, { useRef, useState } from 'react';
import PostModel from './PostModel';
import './CategoryModel.scss';


const CategoryModel = ({ posts }) => {
   // Ensuring posts is always an array
   

   return (
       <div className='CLSS'>
           {posts.length > 0 ? (
               posts.map((post) => (
                   <PostModel
                       key={post.id}
                       postId={post.post_id}
                       userId={post.user_id}
                       userName = {post.username}
                       userProfPic = {post.profile_picture}
                       postImages={post.images || []} // Ensure it's an array
                       postTitle={decodeURIComponent(post.title) || "Untitled"} // Default title
                       postContent={decodeURIComponent(post.content) || "No content available."} // Default content
                       likes={post.likes_count || 0} // Default to 0 likes
                       views={post.views || 0} // Default to 0 views
                       time={post.created_at || "Just now"} // Default time value
                       isLiked = {post.is_liked || false}
                       isSaved = {post.is_saved || false}
                       category = {post.category || "Common"}
                   />
               ))
           ) : (
               <p>No posts available.</p> // Message if no posts are found
           )}
       </div>
   );
};

export default CategoryModel;