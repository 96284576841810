import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Explore.scss';
import Surfing from '../../icons/surfing.png';
import { DomainUrl } from '../../config';

const draftDefaultImage = '/DefaultImg/postDefault.jpg';

const Explore = () => {
  const [trendData, setTrendData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchTopCategories = async () => {
      try {
        const response = await fetch(`${DomainUrl}/api/explore/getblazingbuzz`);
        const data = await response.json();
        setTrendData(data);
      } catch (error) {
        console.error('Error fetching top categories:', error);
      }
    };

    fetchTopCategories();
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm.length > 0) {
        fetchSearchResults();
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const fetchSearchResults = async () => {
    try {
      // Encode the entire search term for the API call
      const encodedSearchTerm = encodeURIComponent(searchTerm);
      const response = await fetch(`${DomainUrl}/api/explore/explore?term=${encodedSearchTerm}`);
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const decodeResultName = (result) => {
    return result.type === 'post' ? decodeURIComponent(result.name) : decodeURIComponent(result.name);
  };

  return (
    <div>
      <div className='exploreContainer'>
        <div className='surfSpace'>
          <div className='searchBox'>
            <input 
              type='text' 
              placeholder='Surf through posts and categories' 
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ 
                backgroundImage: `url(${Surfing})`, 
                backgroundSize: '20px 20px', 
                backgroundPosition: '10px 10px', 
                backgroundRepeat: 'no-repeat', 
                padding: '10px 10px 10px 40px' 
              }} 
            />
          </div>
          {searchResults.length > 0 && (
            <div className='searchResults'>
              {searchResults.map((result, index) => (
                <Link 
                  key={index}
                  to={result.type === 'category' ? `/categoryList/${result.name}` : `/post/${result.id}`}
                  className={`searchResult ${result.type}`}
                >
                  {decodeResultName(result)}
                </Link>
              ))}
            </div>
          )}
        </div>

        <div className='trendText'>
          <h2>BlazingBuzz</h2>
        </div>

        <div className='trendSpace'>
          {trendData.slice(0, 6).map((item, index) => (
            <div key={index} className='trendBox'>
              <Link 
                to={`/categoryList/${item.categoryName}`} 
                state={{ item: item.categoryName }} 
                style={{ textDecoration: 'none' }}>
                <img 
                  src={item.imageUrl ? `https://heybruh.nyc3.cdn.digitaloceanspaces.com/uploads/posts/${item.imageUrl}` : draftDefaultImage}
                  alt={item.categoryName} 
                />
                <p>{decodeURIComponent(item.categoryName)}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Explore;
// Break Point