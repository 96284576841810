import React from 'react';
import '../categoryList/CategoryListShow.scss';
import DraftPost from './DraftPost';
import { Users, Draft } from '../../db'; // Import Users data

const DraftListShow = ({ posts }) => {

    

  return (
    <div className='CLS'>
      {posts.map((post) => (
        <DraftPost
          key={post.draft_id}
          postId={post.draft_id}
          postImages={post.images}
          postTitle={post.title}
          postContent={post.content}
          time={post.created_at}
          userName = {post.username}
          userProfPic = {post.profile_picture}
        />
      ))}
    </div>
  );
};

export default DraftListShow;

