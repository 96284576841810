import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Register.scss";
import { Link } from 'react-router-dom';
import axios from 'axios';  // Import axios to make API calls
import { DomainUrl } from '../../config';

const Register = () => {
  const logo = "/logo512.png";
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');  // To display error messages
  const [showPassword, setShowPassword] = useState(false);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Email validation
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    // Basic validation
    if (email && password) {
      try {
        // Make an API request to check if the email already exists
        const response = await axios.post(`${DomainUrl}/api/auth/checkemail`, { email });

        // If the email exists, show an error
        if (response.data.exists) {
          setError('This email is already in use. Try logging in.');
        } else {
          // If the email doesn't exist, navigate to the UserInfo page
          navigate('/profile-details', { state: { email, password } });
        }
      } catch (err) {
        console.error(err);
        setError('Something went wrong. Please try again.');
      }
    } else {
      setError('Please enter both email and password');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (error === 'Please enter a valid email address') {
      setError('');
    }
  };

  return (
    <div className="register-container">
      <div className="left-side">
        <h2>Welcome to HeyBruh</h2>
        <p>
          HeyBruh isn't just another social platform—it's where 
          you can be yourself. Share what matters to you, connect
          with people who get it, and find friends who share your 
          interests. Join a community that values real connections
          and celebrates who you are. Be real. Be you. 
        </p>
      </div>
      <div className="right-side">
        <div className="card">
          <img src={logo} alt='Logo' className='logo' />
          <form className='form' onSubmit={handleRegister}>
            <input 
              type="text" 
              placeholder="Enter your email" 
              value={email}
              onChange={handleEmailChange}
            />
            <>
              <input 
                type={showPassword ? "text" : "password"}
                placeholder="Create a password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="show-password" onClick={toggleShowPassword}>
                {showPassword ? "Hide" : "Show"} password
              </span>
            </>
            <button type="submit" className='register-btn'>Register</button>
          </form>
          {error && <p className="error-message">{error}</p>}  {/* Display error messages */}
          <p className="divider">-or-</p>
          <p>Already have an account?</p>
          <Link to="/login">
            <button className='login-btn'>Login</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;