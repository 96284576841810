import React from 'react';
import './About.scss';

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1 className="about-heading">About HeyBruh</h1>
        
        <p>
          Welcome to HeyBruh, a platform built for real stories, real connections, and real conversations. This is a space where you can be yourself, share your life’s moments, write short stories, or even use it as your personal blog to express whatever’s on your mind. Whether you’re passionate about sharing your thoughts on the world, or you simply want to connect with others who share your interests, HeyBruh is the place where true emotions take center stage. 
        </p>

        <p>
          Here, you’ll discover a community where people talk freely about what's really happening around them. You'll be able to explore diverse topics, find inspiration in others' stories, and maybe even inspire someone else to write and share their own experiences. Whether it's something emotional, funny, or reflective, this is a space for everyone to express their truth without the filters, scripts, or toxicity that often comes with modern-day social media.
        </p>

        <h2 className="about-subheading">Our Story</h2>
        
        <p>
          One day, I was scrolling through Instagram and YouTube, feeling that familiar pit in my stomach. Seeing everyone seemingly doing better than me made me question myself, even though deep down, I knew it wasn’t the whole picture. Like many others, I realized that most of what I was seeing was heavily filtered, carefully scripted, and far from real life. Still, it’s hard not to compare. As an introvert who doesn’t speak much, I wondered, "Is there a place where I can express my true self, where I can connect with like-minded people, without the filters and performances?" 
        </p>

        <p>
          And that’s when it hit me — why not create a platform for real social connections? A place where people can be honest, genuine, and creative without the pressure of keeping up with an idealized version of life. A platform to bring people together through stories that touch their emotions and help them find true connections.
        </p>

        <h2 className="about-subheading">A Work in Progress</h2>

        <p>
          HeyBruh is still in its early stages, and while we're thrilled to have you here, we understand there might be some flaws. We're continuously working to address any issues and improve the platform to make it the best space it can be for you.
        </p>

        <p className="about-thankyou">
          Thank you for being a part of HeyBruh. Let's keep it real, together.
        </p>
      </div>
    </div>
  );
};

export default About;
