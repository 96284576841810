import React, { useState, useEffect  } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './AccSettings.scss';
import { useUser } from '../../App';
import { DomainUrl } from '../../config';

import ShowPasswordbtn from "../../icons/showpsw.png";
import HidePasswordbtn from "../../icons/hidepsw.png";

const AccSettings = () => {
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showBlockedUsers, setShowBlockedUsers] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);
  const userId = useUser();
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  const [showContactPopup, setShowContactPopup] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // Dummy data for blocked users with profile pictures
  const blockedUsers = [
    { email: 'user1@example.com', profilePic: 'profile1.jpg' },
    { email: 'user2@example.com', profilePic: 'profile2.jpg' }
  ];


  useEffect(() => {
    const fetchUserEmail = async () => {
      if (!userId) {
        console.warn("userId is undefined, aborting fetch");
        return;
      }
      try {
        const response = await fetch(`${DomainUrl}/api/auth/getemailid/${userId}`, {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Failed to fetch user email');
        }
        const data = await response.json();
        setUserEmail(data.email);
      } catch (error) {
        console.error('Error fetching user email:', error);
      }
    };

    fetchUserEmail();
  }, [userId]);

  const toggleBlockedUsers = () => {
    setShowBlockedUsers(!showBlockedUsers);
  };

  const togglePasswordVisibility = () => {
    setShowPasswords(!showPasswords);
  };

  const handlePasswordChange = async () => {
    if (!userId) {
      console.warn("username is undefined, aborting fetch");
      return;
    }
    // Reset error and success messages
    setPasswordError('');
    setPasswordSuccess('');

    // Basic validation
    if (!currentPassword || !newPassword || !confirmPassword) {
      setPasswordError('All fields are required');
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordError('New password and confirm password do not match');
      return;
    }

    // Set loading state
    setIsLoading(true);

    try {
      // Make API call to change password
      const response = await fetch(`${DomainUrl}/api/auth/changepassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
          userId,
        })
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.message === 'Current password is incorrect.') {
          throw new Error('The current password is wrong. Please enter the correct password.');
        } else {
          throw new Error(data.message || 'Failed to change password');
        }
      }

      // Password change successful
      setPasswordSuccess(data.message || 'Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setShowPasswordFields(false);
    } catch (error) {
      setPasswordError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    if (!userEmail) {
      return;
    }
    // Navigate to ResetPassword component with email as state
    navigate('/reset-password', { state: { email: userEmail } });
  };


  const toggleContactPopup = () => {
    setShowContactPopup(!showContactPopup);
  };

  const copyEmail = () => {
    navigator.clipboard.writeText('support@heybruh.in');
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };


  return (
    <div className={`acc-settings-container ${showBlockedUsers ? 'blurred' : ''}`}>
      <h1 className="acc-settings-heading">Settings</h1>

      <section className="settings-section">
        <h2 className="section-heading">Account Settings</h2>
        <div className="email-id-container">
          <h3 className="email-id-heading">Email ID Used:</h3>
          <p className="email-id-text">{userEmail || 'Loading...'}</p>
        </div>
        <div className="password-buttons">
          <button
            className="change-password-btn"
            onClick={() => setShowPasswordFields(!showPasswordFields)}
            disabled={isLoading}
          >
            Change Password
          </button>
          {showPasswordFields && (
            <button
              className="show-password-btn"
              onClick={togglePasswordVisibility}
              disabled={isLoading}
            >
              {showPasswords ? <img src={HidePasswordbtn} /> : <img src={ShowPasswordbtn} />}
            </button>
          )}
        </div>

        {showPasswordFields && (
          <div className="password-fields-container">
            <input
              type={showPasswords ? "text" : "password"}
              placeholder="Current Password"
              className="password-input"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              disabled={isLoading}
            />
            <input
              type={showPasswords ? "text" : "password"}
              placeholder="New Password"
              className="password-input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={isLoading}
            />
            <input
              type={showPasswords ? "text" : "password"}
              placeholder="Confirm Password"
              className="password-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isLoading}
            />
            <button 
              className="save-btn" 
              onClick={handlePasswordChange}
              disabled={isLoading}
            >
              {isLoading ? 'Changing...' : 'Save'}
            </button>
            {passwordError && <p className="error-text">{passwordError}</p>}
            {passwordSuccess && <p className="success-text">{passwordSuccess}</p>}
            <p className="forgot-password-text" onClick={handleForgotPassword}>Forgot Password?</p>
          </div>
        )}
      </section>

      {/* <section className="settings-section">
        <h2 className="section-heading">Blocked Users</h2>
        <button className="view-blocked-users-btn" onClick={toggleBlockedUsers}>
          View Blocked Users
        </button>
        {showBlockedUsers && (
          <div className="blocked-users-popup">
            <button className="close-popup-btn" onClick={toggleBlockedUsers}>x</button>
            <h3 className="popup-heading">Blocked Users</h3>
            <ul className="blocked-users-list">
              {blockedUsers.map((user, index) => (
                <li key={index} className="blocked-user-item">
                  <img src={user.profilePic} alt="Profile" className="profile-pic" />
                  <span className="user-email">{user.email}</span>
                  <button className="unblock-btn">Unblock</button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section> */}

      <section className="settings-section">
        <h2 className="section-heading">Get Help</h2>
         <span className="help-btn" onClick={toggleContactPopup}>
          Contact Us
        </span>
      </section>

      <section className="settings-section">
        <h2 className="section-heading">About App</h2>
        <Link to="/about" className="about-btn">
            Know about this app
        </Link>

      </section>

      {showContactPopup && (
        <div className="contact-popup">
          <div className="contact-popup__content">
            <button className="close-popup-btn" onClick={toggleContactPopup}>×</button>
            <h3 className="popup-heading">Contact Us</h3>
            <p>Email your queries or complaints to this email ID. Our customer support will reach you as soon as possible. Thank you!</p>
            <div className="email-container">
              <input type="text" value="support@heybruh.in" readOnly />
              <button onClick={copyEmail}>{isCopied ? 'Copied!' : 'Copy'}</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default AccSettings;